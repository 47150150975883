<template>
    <!-- タグで複数選択。新規に追加した値は、v-modelに配列で設定される。例えば090と0120を追加した場合は、['090', '0120']となる。
    labelにnameを指定した場合、v-modelの入力は、[ {name: '03'}, {name: '044'}]　などを想定している。090と0120を追加した場合、[ {name: '03'}, {name: '044'}, '090', '0120']となる-->
    <vue-select taggable multiple  :label="label"   v-model="inputedValue">
        <span slot="no-options">値を入力してください</span>
    </vue-select>

</template>

<script>
    export default {
        name: 'tag-select',
        props: {
            value: {
                type: Array,
            },
            label: {
                type: String,

            },
        },
        computed: {
            inputedValue: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    this.$emit("input", newValue);
                },
            },
        },

        data () {
            return {
            }
        },
        methods: {
        },
        mounted() {
        },
        created() {
        }
    }
</script>
