<template>
    <!-- 日付選択ダイアログ-->
  <v-dialog
    v-model="is_show_dialog"
    persistent
    width="820px"
  >
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="3" class="pa-0 ma-0">
            <v-card flat tile outlined height="100%">

              <v-list class="pl-3 pt-3">
                <v-list-item-group
                  active-class="border"
                  color="indigo"
                >
                  <v-list-item
                    v-for="(item, i) in started_at_preset_items"
                    :key="i"
                    @click="onSelectPresetItem(item)"
                  >
                
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

            </v-card>

          </v-col>

          <v-col cols="9" class="pa-0 ma-0">
            <v-card flat tile outlined height="100%">
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <my-datetimepicker v-model="my_interval_from" class="flex" title="開始"></my-datetimepicker>
                  </v-col>
                  <v-col cols="6">
                    <my-datetimepicker v-model="my_interval_to" class="flex" title="終了"></my-datetimepicker>
                  </v-col>
                </v-row>

              </v-container>


            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="onCloseStartedAtDialog(false)"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="onCloseStartedAtDialog(true)"
        >
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import MyDatetimepicker from './MyDatetimepicker.vue';
import my_date_lib from '../../my_date';
    export default {
        components: { MyDatetimepicker },
        name: 'dateinterval-dialog',
        props: {
            interval_from: {
                type: Date,
            },
            interval_to: {
                type: Date,
            },
        },
        computed: {
            my_interval_from : {
                get() {
                    return this.interval_from;
                },
                set(newValue) {
                    this.$emit('update:interval_from', newValue);
                }

            },
            my_interval_to : {
                get() {
                    return this.interval_to;
                },
                set(newValue) {
                    this.$emit('update:interval_to', newValue);
                }

            },
        },

        data () {
            return {
              is_show_dialog: false,
              started_at_preset_items: [
                { title: '今日', type: 1, days: 1},
                { title: '昨日', type: 2, days: 1},
                { title: '今週', type: 3, days: 7},
                { title: '先週', type: 4, days: 7},
                { title: '過去7日間', type: 5, days: 7},
                { title: '今月', type: 6, days: 0},
                { title: '先月', type: 7, days: 0},
                { title: '過去30日間', type: 8, days: 30},

              ],
            }
        },
        methods: {
          open() {
            this.is_show_dialog = true;
          },
          onCloseStartedAtDialog(is_done) {

            if (is_done) {
                //  更新
                this.$emit('done');
            } else {
                this.$emit('canceled');

            }
            //  ダイアログを閉じる
            this.is_show_dialog = false

          },
          //  日付範囲のプリセット選択時
          onSelectPresetItem(item) {
            console.log('onSelectPresetItem', item);
            let days = item.days;
            let dt_to;
            let dt_from;
            let today = my_date_lib.getToday();
            switch (item.type) {
              case 1:  // 今日
              case 5:  // 過去７日
              case 8:  // 過去30日
                dt_to = my_date_lib.getNextday();
                break;
              case 2:  // 昨日
                dt_to = today;
                break;
              case 3:  // 今週
                today.setDate(today.getDate() - today.getDay() + 7);
                dt_to = today;
                break;
              case 4:  // 先週
                today.setDate(today.getDate() - today.getDay());
                dt_to = today;
                break;
              case 6:  // 今月
                dt_to = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                dt_from = new Date(today.getFullYear(), today.getMonth(), 1);
                break;
              case 7:  // 先月
                dt_to = new Date(today.getFullYear(), today.getMonth(), 1);
                dt_from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                break;

            }
            if (days) {
              dt_from = my_date_lib.getDayAdd(dt_to, -days);
            }

            this.my_interval_from = dt_from;
            this.my_interval_to = dt_to;
          },

        },
        mounted() {
        },
        created() {
        }
    }
</script>
