
<template>
<v-main>
    <v-container>

        <v-form ref="form">
        <v-row  align-content="center" style="height:500px">
        <v-col align="center">
        
        <v-card flat class="d-flex justify-center mb-10">
            <v-img src="/images/logo_genesys_180.png" max-width="130px" />
            <p class="text-h2">Voice-First</p>
        </v-card>
        <v-card flat class="d-flex justify-center">
            <v-card flat class="pa-2">
                <v-text-field  label="Email" v-model="form.email" :rules="[rules.required]" clearable></v-text-field>
            </v-card>
            <v-card flat class="pa-2">
                <v-text-field 
                            v-model="form.password"
                            :append-icon="is_show_password ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="is_show_password ? 'text' : 'password'"
                            label="パスワード"
                            hint="6文字以上"
                            counter
                            @click:append="is_show_password = !is_show_password"
                        ></v-text-field>
            </v-card>
            <v-card flat class="pl-2 pt-6">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="doLogin"><v-icon>mdi-login</v-icon></v-btn>
                    </template>
                    <span>ログイン</span>
                </v-tooltip>
            </v-card>
        </v-card>
  
        <v-card flat class="d-flex justify-center">
            <p v-if="error_message" class="red--text">{{error_message}}</p>
        </v-card>
        </v-col>
        </v-row>
        </v-form>
    </v-container>
</v-main>
</template>

<script>
    export default {
        data(){
            return {
                is_show_password: false,
                rules: {
                  required: value => !!value || '必須入力',
                  min: v => v.length >= 6 || '6文字以上',
                },
                form: new Form({
                    email: '',
                    password: '',
                }),
                error_message: null,

            }
        },
        mounted() {
        },
        methods:{
            doLogin() {
                if (this.$refs.form.validate()) {

                    document.getElementById('email').value = this.form.email;
                    document.getElementById('password').value = this.form.password;

                    //  login.blade.phpのなかでform_loginが記述されている
                    document.getElementById('form_login').submit();
                    return false;
                }



            },

        },

        created() {
            let elm = document.getElementById('error_message_email');
            if (elm) {
                this.error_message = elm.value;
            } else {
                elm = document.getElementById('error_message_password');
                if (elm) {
                    this.error_message = elm.value;
                }

            }
            
        }
    }
</script>
