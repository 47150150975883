<template>
  <v-main>
        <v-card  flat outlined tile class="ma-3" :loading="is_loading">
            <v-toolbar dense  elevation="1">
                <v-toolbar-title v-show="!is_show_find"  class="text-subtitle-2">ユーザー一覧</v-toolbar-title>

                <v-text-field @blur="getRows()"  @keyup.enter="getRows()" v-model="find_text" class="mt-4" v-show="is_show_find"
                  prepend-icon="mdi-magnify"
                  append-icon="mdi-close"
                  @click:append="is_show_find=false;getRows();"
                >
                </v-text-field>                
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon  @click="is_show_find=true;getRows();">
                        <v-icon color="primary">mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>検索</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" v-if="can(settings.Permissions.EDIT_USER)" icon  @click="newModal">
                        <v-icon color="primary">mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>ユーザー追加</span>
                </v-tooltip>
                <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon small color="grey darken-4" @click="getRows()">
                          <v-icon small>mdi-reload</v-icon>
                        </v-btn>
                      </template>
                      <span>表示更新</span>
                  </v-tooltip>

            </v-toolbar>
            <v-simple-table class="v-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>名前</th>
                    <th>Email</th>
                    <th>グループ</th>
                    <th>メモ</th>
                    <th>登録日</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="user in rows.data" :key="user.id">

                    <td>{{user.id}}</td>
                    <td class="text-capitalize">{{user.name}}</td>
                    <td>{{user.email}}</td>
                    <td>{{groupName(user.group_id)}}</td>
                    <td>{{user.memo}}</td>
                    <td>{{user.created_at | moment_datetime}}</td>

                    <td>

                      <a href="#" @click="editModal(user)">
                          <v-icon color="blue">mdi-application-edit-outline</v-icon>
                      </a>
                      <span  v-if="can(settings.Permissions.EDIT_USER)">
                      /
                      <a href="#" @click="deleteUser(user.id)">
                          <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </a>
                    </span>
                    </td>
                  </tr>
                </tbody>

              </template>
            </v-simple-table>


            <v-card-actions>
                <v-spacer></v-spacer>
                <v-pagination
                    v-model="selected_page"
                    circle
                    class="my-4"
                    :length="pager_length"
                    @next="onPagination()"
                    @previous="onPagination()"
                    @input="onPagination()"
                ></v-pagination>
                <v-spacer></v-spacer>

            </v-card-actions>
        </v-card>



  <!-- ダイアログ-->
  <v-dialog
    v-model="is_show_dialog"
    width="480"
    persistent
  >
    <v-card>
      <v-toolbar dense  elevation="1">
          <v-toolbar-title  class="text-subtitle-2">{{ editmode ? '更新' : '新規ユーザー' }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn  icon  @click="is_show_dialog = false;">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-text-field label="名前" v-model="form.name" :rules="[rules.required]" clearable></v-text-field>
          <v-text-field label="Email" v-model="form.email" :rules="[rules.required]" clearable></v-text-field>
          <v-text-field
                v-model="form.password"
                :append-icon="is_show_password ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="editmode ? [] : [rules.required, rules.min]"
                :type="is_show_password ? 'text' : 'password'"
                label="パスワード"
                hint="6文字以上"
                counter
                @click:append="is_show_password = !is_show_password"
              ></v-text-field>   

          <!-- v-select
                v-model="form.type"
                :items="[{ text: '管理者', value: 'admin'}, { text: '一般ユーザー', value: 'user'}]"
                :rules="[rules.required]"
                label="役割"
                dense
                ></v-select -->
          <v-select v-if="groups"
                v-model="form.group_id"
                :items="groups"
                :rules="[rules.required]"
                label="グループ"
                dense
                ></v-select>
          <v-text-field label="メモ" v-model="form.memo" clearable></v-text-field>
          <template v-if="role_names">
          <label>役割</label>
          <v-checkbox v-for="role_name in role_names" :key="role_name" class="pa-0 ma-0"
              v-model="form.role_names"
              :label="role_name"
              :value="role_name"
            ></v-checkbox>
          </template>



        </v-form>

      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="is_show_dialog=false;">キャンセル</v-btn>
          <v-btn v-if="can(settings.Permissions.EDIT_USER)"  text color="primary" @click="onUpdateUser()">
            {{ editmode ? '更新' : '登録'}}
          </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>


  </v-main>
</template>

<script>
import settings from '../consts/settings';
import moment from 'moment';
    export default {
        filters: {
            moment_datetime: function (date) {
                return moment(date).format('YYYY-MM-DD HH:mm:ss');
            }
        },        
        computed: {
          settings: function () {
            return settings;
          },
          pager_length: function() {
            let length = 0;
            if (this.rows && this.rows.data) {
              length = this.rows.current_page;
              let count = this.rows.data.length;
              let limit = this.rows.per_page;
              if (count == limit) {
                //  すべて取得できたとき。次のページもあるかもしれないので、ページ数をアップ
                length = length + 1;
              }
            }
            return length;
          },
        },

        data () {
            return {
                selected_page: 1,       //  現在選択されているページ（pager用）
                is_show_dialog: false,
                is_show_password: false,
                rules: {
                  required: value => !!value || '必須入力',
                  min: v => (v && v.length >= 6) || '6文字以上',
                  emailMatch: () => (`The email and password you entered don't match`),
                },
                editmode: false,
                rows : {},
                form: new Form({
                    id : '',
//                    type : '',
                    name: '',
                    email: '',
                    password: '',
                    email_verified_at: '',
                    group_id: null,
                    memo: '',
                    role_names: [],
                }),
                groups: null,
                role_names: null,
                is_show_find: false,
                find_text: null,
                is_loading: false,
            }
        },
        methods: {
          //  ページネーションクリック
          onPagination() {
            //  チェックははずさないように
            this.getRows(this.selected_page);
          },
          onUpdateUser() {
            if (this.$refs.form.validate()) {

              if (this.editmode) {
                this.updateUser();

              } else {
                this.createUser();
              }
            }

          },




            getRows(page = 1) {
              this.is_loading = true;

//                  this.$Progress.start();
              let find_text = this.is_show_find ? this.find_text : null;
              let param = find_text ? ('&find_text=' + encodeURIComponent(find_text)) : '';
              axios.get('api/user?page=' + page + param).then(({ data }) => {
                this.rows = data.data;
                this.is_loading = false;
           //     console.log('users', this.rows);
              }).catch((data)=> {
                Swal.fire("Failed!", data.message, "warning");
                this.is_loading = false;
              });

//                  this.$Progress.finish();
            },
            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/user/'+this.form.id)
                .then((response) => {
                    // success
//                    $('#addNew').modal('hide');
                    this.is_show_dialog = false;
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadUsers();
                }).catch((data)=> {
                  let title = data.message;
                  if (title.indexOf('Request failed with status code 422') == 0) {
                    //  TODO:  phpの方でエラーをキャッチして適切なメッセージを設定できないか検討するように
                    title = 'すでに登録済みのemailが指定されていないかご確認ください';
                  }
                  Swal.fire("Failed!", title, "warning");
                });

            },
            editModal(user){

                this.editmode = true;
                this.form.reset();
//                $('#addNew').modal('show');
                this.form.fill(user);
                this.is_show_dialog = true;
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
//                $('#addNew').modal('show');
                this.is_show_dialog = true;
            },
            deleteUser(id){
                Swal.fire({
                    title: '本当によろしいですか?',
                    text: "この操作は取り消せません",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: '削除'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                              this.form.delete('api/user/'+id).then(()=>{
                                    Swal.fire(
                                    '削除しました!',
                                    '',
                                    '成功'
                                    );
                                // Fire.$emit('AfterCreate');
                                this.loadUsers();
                              }).catch((data)=> {
                                Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },
          loadUsers(){
            this.getRows();
            /*
            this.$Progress.start();

            if(this.$gate.isAdmin()){
              axios.get("api/user").then(({ data }) => (this.rows = data.data));
            }

            this.$Progress.finish();
            */
          },
          
          createUser(){

            this.$Progress.start();
              this.form.post('api/user')
              .then((response)=>{
//                  $('#addNew').modal('hide');
                  this.is_show_dialog = false;

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadUsers();

              }).catch((data)=> {
                let title = data.message;
                if (title.indexOf('Request failed with status code 422') == 0) {
                  //  TODO:  phpの方でエラーをキャッチして適切なメッセージを設定できないか検討するように
                  title = 'すでに登録済みのemailが指定されていないかご確認ください';
                }
                Swal.fire("Failed!", title, "warning");
              });
          },
          getAllGroups() {
            axios.get('api/group_all').then(({ data }) => {
                let groups = data.data;
                //  グループ一覧をselectタグで表示できる形式に変換
                this.groups = groups.map(group => {
                  return {text: group.name, value: group.id};
                });
            }).catch((data)=> {
              this.groups = null;
            });
            
          },
          groupName(id) {
            let name = null;
            if (this.groups) {

              let group = this.groups.find(group => {
                return group.value == id;
              });
              if (group) {
                name = group.text;
              }
            } 
            return name;
          },
          getAllRoleNames() {
            axios.get('api/role_all_name').then(({ data }) => {
                this.role_names = data.data;
            }).catch((data)=> {
              this.role_names = null;
            });
            
          },


        },
        mounted() {
        //   console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadUsers();
            this.getAllGroups();
            this.getAllRoleNames();
            this.$Progress.finish();
        }
    }
</script>
