<template>
    <v-main>
          <v-card flat outlined tile class="ma-3" :loading="is_loading">
              <v-toolbar dense  elevation="1">
                  <v-toolbar-title v-show="!is_show_find"  class="text-subtitle-2">グループ一覧</v-toolbar-title>
                  <v-text-field @blur="getRows()"  @keyup.enter="getRows()" v-model="find_text" class="mt-4" v-show="is_show_find"
                  prepend-icon="mdi-magnify"
                  append-icon="mdi-close"
                  @click:append="is_show_find=false;getRows();"
                >
                </v-text-field>                
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon  @click="is_show_find=true;getRows();">
                        <v-icon color="primary">mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>検索</span>
                </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on"  v-if="can(settings.Permissions.EDIT_GROUP)"  icon  @click="newModal">
                        <v-icon color="primary">mdi-plus-circle</v-icon>
                    </v-btn>

                    </template>
                    <span>グループ追加</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon small color="grey darken-4" @click="getRows()">
                          <v-icon small>mdi-reload</v-icon>
                        </v-btn>
                      </template>
                      <span>表示更新</span>
                  </v-tooltip>

              </v-toolbar>
              <v-simple-table class="v-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>名前</th>
                      <th>メモ</th>
                      <th>登録日</th>
                      <th>操作</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-for="group in rows.data" :key="group.id">
  
                      <td>{{group.id}}</td>
                      <td class="text-capitalize">{{group.name}}</td>
                      <td>{{group.memo}}</td>
                      <td>{{group.created_at | moment_datetime}}</td>
  
                      <td>
  
                        <a href="#" @click="editModal(group)">
                            <v-icon color="blue">mdi-application-edit-outline</v-icon>
                        </a>
                        <span  v-if="can(settings.Permissions.EDIT_GROUP)">
                        /
                        <a href="#" @click="deleteGroup(group.id)">
                            <v-icon color="red">mdi-trash-can-outline</v-icon>
                        </a>
                      </span>
                      </td>
                    </tr>
                  </tbody>
  
                </template>
              </v-simple-table>
  
  
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-pagination
                      v-model="selected_page"
                      circle
                      class="my-4"
                      :length="pager_length"
                      @next="onPagination()"
                      @previous="onPagination()"
                      @input="onPagination()"
                  ></v-pagination>
                  <v-spacer></v-spacer>
  
              </v-card-actions>
          </v-card>
  
  
  
    <!-- ダイアログ-->
    <v-dialog
      v-model="is_show_dialog"
      width="480"
      persistent
    >
      <v-card>
        <v-toolbar dense  elevation="1">
            <v-toolbar-title  class="text-subtitle-2">{{ editmode ? '更新' : '新規グループ' }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn  icon  @click="is_show_dialog = false;">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-text-field label="名前" v-model="form.name" :rules="[rules.required]" clearable></v-text-field>
            <v-text-field label="メモ" v-model="form.memo"  clearable></v-text-field>


            <label>キュー</label>
            <v-checkbox v-for="queue in queues" :key="queue.id" class="pa-0 ma-0"
              v-model="form.selected_queue_ids"
              :label="queue.name"
              :value="queue.id"
            ></v-checkbox>
  
          </v-form>
  
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="is_show_dialog=false;">キャンセル</v-btn>
            <v-btn v-if="can(settings.Permissions.EDIT_GROUP)"   text color="primary" @click="onUpdateGroup()">
              {{ editmode ? '更新' : '登録'}}
            </v-btn>
  
        </v-card-actions>
      </v-card>
  
    </v-dialog>
  
  
    </v-main>
  </template>
  
  <script>
import settings from '../consts/settings';
import moment from 'moment';
      export default {
          filters: {
              moment_datetime: function (date) {
                  return moment(date).format('YYYY-MM-DD HH:mm:ss');
              }
          },        
          computed: {
            settings: function () {
              return settings;
            },
            pager_length: function() {
              let length = 0;
              if (this.rows && this.rows.data) {
                length = this.rows.current_page;
                let count = this.rows.data.length;
                let limit = this.rows.per_page;
                if (count == limit) {
                  //  すべて取得できたとき。次のページもあるかもしれないので、ページ数をアップ
                  length = length + 1;
                }
              }
              return length;
            },
          },
  
          data () {
              return {
                  selected_page: 1,       //  現在選択されているページ（pager用）
                  is_show_dialog: false,
                  is_show_password: false,
                  rules: {
                    required: value => !!value || '必須入力',
                  },
                  editmode: false,
                  rows : {},
                  form: new Form({
                      id : '',
                      name: '',
                      memo: '',
                      selected_queue_ids: [],
                  }),
                  queues: {},
                  is_show_find: false,
                  find_text: null,
                  is_loading: false,
              }
          },
          methods: {
            //  ページネーションクリック
            onPagination() {
              //  チェックははずさないように
              this.getRows(this.selected_page);
            },
            onUpdateGroup() {
              if (this.$refs.form.validate()) {
  
                if (this.editmode) {
                  this.updateGroup();
  
                } else {
                  this.createGroup();
                }
              }
  
            },
  
  
  
  
              getRows(page = 1) {
  
                this.is_loading = true;
                let find_text = this.is_show_find ? this.find_text : null;
                let param = find_text ? ('&find_text=' + encodeURIComponent(find_text)) : '';

                axios.get('api/group?page=' + page + param).then(({ data }) => {
                        this.rows = data.data;
//                        console.log('group rows', this.rows);
                      this.is_loading = false;
                    }).catch((data)=> {
                      Swal.fire("Failed!", data.message, "warning");
                      this.is_loading = false;
                    });
  
              },
              getAllQueues() {
                this.$Progress.start();
                axios.get('api/gc/queue_all').then(({ data }) => {
                    this.queues = data.data;
                    this.$Progress.finish();
                });
                
              },
              updateGroup(){
                  this.$Progress.start();
                  // console.log('Editing data');
                  this.form.put('api/group/'+this.form.id)
                  .then((response) => {
                      // success
  //                    $('#addNew').modal('hide');
                      this.is_show_dialog = false;
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');
  
                      this.loadGroups();
                  }).catch((data)=> {
                    Swal.fire("Failed!", data.message, "warning");
                  });
  
              },
              editModal(group){
  
                this.editmode = true;
                this.form.reset();
                if (group.queues) {
                  //  フォームに設定されるように、キューIDの配列を作成
                  group.selected_queue_ids = group.queues.map(queue => queue.id);
                }
                this.form.fill(group);
                this.is_show_dialog = true;
              },
              newModal(){
                  this.editmode = false;
                  this.form.reset();
  //                $('#addNew').modal('show');
                  this.is_show_dialog = true;
              },
              deleteGroup(id){
                  Swal.fire({
                      title: '本当によろしいですか?',
                      text: "この操作は取り消せません",
                      showCancelButton: true,
                      confirmButtonColor: '#d33',
                      cancelButtonColor: '#3085d6',
                      confirmButtonText: '削除'
                      }).then((result) => {
  
                          // Send request to the server
                           if (result.value) {
                                  this.form.delete('api/group/'+id).then(()=>{
                                          Swal.fire(
                                          '削除しました!',
                                          '',
                                          '成功'
                                          );
                                      // Fire.$emit('AfterCreate');
                                      this.loadGroups();
                                  }).catch((data)=> {
                                    Swal.fire("Failed!", data.message, "warning");
                                });
                           }
                      }).catch((data)=> {
                        Swal.fire("Failed!", data.message, "warning");
                      });
              },
              loadGroups(){
                this.getRows();
  
            },
            
            createGroup(){
  
                this.form.post('api/group')
                .then((response)=>{
  //                  $('#addNew').modal('hide');
                    this.is_show_dialog = false;
  
                    Toast.fire({
                          icon: 'success',
                          title: response.data.message
                    });
  
                    this.$Progress.finish();
                    this.loadGroups();
  
                }).catch((data)=> {
                  Swal.fire("Failed!", data.message, "warning");
                });
            }
  
          },
          mounted() {
//              console.log('Group Component mounted.')
          },
          created() {
              this.loadGroups();
              this.getAllQueues();
          }
      }
  </script>
  