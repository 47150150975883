<template>
    <!--  v-modelは、nameとidではなく、nameとvalueのときもある。code="id"を指定しているが、idが存在しない場合はvalueをみてくれているみたい。現状どちらもうまく動いている。-->
    <vue-select label="name" code="id" :options="options" v-model="inputedValue" multiple :filterable="false" @search="(search, loading) => onSearch(search, loading)">
        <span slot="no-options">検索候補がありません</span>
    </vue-select>

</template>

<script>
    export default {
        name: 'master-select',
        props: {
            value: {
                type: Array,
            },
            select_api: {
                type: String,

            },
        },
        computed: {
            inputedValue: {
                get() {
                    console.log('values', this.value);
                    return this.value;
                },
                set(newValue) {
                    this.$emit("input", newValue);
                },
            },
        },

        data () {
            return {
                options: [],
            }
        },
        methods: {

            onSearch(input_text, loading) {


                loading(true);
                let select_api = this.select_api;

                let params = [
                    'page=1',
                    'limit=50',
                ];
                if (input_text) {
                    params.push('name=' + encodeURIComponent(input_text));
                }
                let query = params.join('&');

                axios.get(select_api + '?' + query).then(({ data }) => {

                    if (data.success) {
                        this.options = data.data.data;
                    }


                    loading(false);
                });

            },

        },
        mounted() {
        },
        created() {
        }
    }
</script>
