<style scoped lang="scss">
    .form {
        .v-card__title {
            margin: 0;
            padding: 0;
        }

    }
</style>
<template>
  <v-main>
        <v-card  flat outlined tile class="ma-3" :loading="is_loading">
            <v-toolbar dense  elevation="1">
                <v-toolbar-title  class="text-subtitle-2">通話録音データーの一括削除とエクスポート一覧</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip v-if="can(settings.Permissions.EDIT_RECORDING_JOB_INFO)"  bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="newModal">
                            <v-icon color="primary">mdi-plus-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>追加</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="getRows()">
                            <v-icon color="primary">mdi-reload</v-icon>
                        </v-btn>
                    </template>
                    <span>リロード</span>
                </v-tooltip>


            </v-toolbar>
            <v-simple-table class="v-table">
              <template v-slot:default>
                <thead>
                  <tr>
                      <th>ID</th>
                      <th>名前</th>
                      <th>アクション</th>
                      <th>対象期間</th>
                      <th>ステータス</th>
                      <th>進捗</th>
                      <th>処理数</th>
                      <th>失敗数</th>
                      <th>登録日時</th>
                      <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                     <tr v-for="row in rows.data" :key="row.id">

                      <td>{{row.id}}</td>
                      <td class="text-capitalize">{{row.name}}</td>
                      <td>{{getActionTitle(row.action)}}</td>
                      <td>{{getIntervalTitle(row)}}</td>
                      <td>{{getStateTitle(row.state)}}</td>
                      <td>{{row.percent_progress}}%</td>
                      <td>{{row.total_processed_recordings}}</td>
                      <td>{{row.total_failed_recordings}}</td>
                      <td>{{row.created_at | moment_datetime}}</td>
                      <td>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="editModal(row)">
                                    <v-icon color="primary">mdi-application-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>詳細</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="!canCancel(row)" icon v-bind="attrs" v-on="on" @click="onCancel(row)">
                                    <v-icon color="red">mdi-cancel</v-icon>
                                </v-btn>
                            </template>
                            <span>キャンセル</span>
                        </v-tooltip>

                      </td>
                    </tr>
                </tbody>

              </template>
            </v-simple-table>


            <v-card-actions>
                <v-spacer></v-spacer>
                <v-pagination
                    v-model="selected_page"
                    circle
                    class="my-4"
                    :length="pager_length"
                    @next="onPagination()"
                    @previous="onPagination()"
                    @input="onPagination()"
                ></v-pagination>
                <v-spacer></v-spacer>

            </v-card-actions>
        </v-card>

  <!-- ダイアログ-->
  <v-dialog
    v-model="is_show_dialog"
    width="800"
    persistent
  >
    <v-card :loading="is_loading">
      <v-toolbar dense  elevation="1">
          <v-toolbar-title  class="text-subtitle-2">{{ editmode ? '詳細' : '新規' }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon  @click="is_show_dialog = false;">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
      </v-toolbar>
        <v-container>
        <v-form ref="form" :disabled="editmode">
        <v-row>
            <v-col class="ma-0 pa-0">
                <v-card flat tile outlined height="100%" class="pl-6 pr-3 py-3">

                <v-text-field label="名前" v-model="form.name" :rules="[rules.required]" clearable></v-text-field>

                <v-card flat tile class="form">
                    <v-card-title>アクション</v-card-title>
                    <v-card-text>

                        <v-card v-if="!editmode"  class="d-flex align-start" flat tile> 
                            <v-checkbox class="ma-0 pa-0"
                                v-model="form.is_export"
                                label="エクスポート"></v-checkbox>
                            <v-checkbox class="ma-0 pa-0"
                                v-model="form.is_delete"
                                :label="form.is_export ? 'エクスポート後に削除' : '削除のみ実行'"></v-checkbox>

                        </v-card>
                        <h3 v-if="editmode">{{getActionTitle(form.action)}}</h3>

                        <v-text-field v-show="form.is_export" label="統合ID" v-model="form.integration_id" :rules="form.is_export ? [rules.required] : []" clearable></v-text-field>
                        <v-checkbox v-show="form.is_export" class="ma-0 pa-0"
                            v-model="form.include_screen_recordings"
                            label="エクスポート時に画面のレコーディングを含める"></v-checkbox>
                        

                    </v-card-text>
        
                </v-card>


                </v-card>

            </v-col>
            <v-col class="ma-0 pa-0">
                <v-card flat tile outlined height="100%" class="pa-3">
                    <v-card flat tile class="form">
                        <v-card-title>日付</v-card-title>
                        <v-card-text v-if="!editmode">
                            <v-btn  text small color="blue darken-4" @click="onOpenStartedAtDialog()">{{interval_title}}</v-btn>
                        </v-card-text>
                        <v-card-text v-if="editmode">
                            {{interval_title}}
                        </v-card-text>
            
                    </v-card>
                    <v-card flat tile class="form">
                        <v-card-title>会話の方向</v-card-title>
                        <v-card-text>
                            <v-card  class="d-flex align-start" flat tile> 
                                <v-checkbox class="ma-0 pa-0"
                                    v-model="form.filter_condition.is_inbound"
                                    label="インバウンド"></v-checkbox>
                                <v-checkbox class="ma-0 pa-0"
                                    v-model="form.filter_condition.is_outbound"
                                    label="アウトバウンド"></v-checkbox>

                            </v-card>

                        </v-card-text>
            
                    </v-card>


                    <v-card flat tile class="form">
                        <v-card-title>接続時間</v-card-title>
                        <v-card-text>
                            <vue-timepicker v-model="form.filter_condition.duration_time_from">
                            </vue-timepicker>
                            〜
                            <vue-timepicker v-model="form.filter_condition.duration_time_to">
                            </vue-timepicker>

                        </v-card-text>
                    </v-card>

                    <v-card flat tile class="form">
                        <v-card-title>特定のユーザー</v-card-title>
                        <v-card-text>
                            <master-select v-model="form.filter_condition.filter_condition_users" :select_api="'/api/gc/user'" />
                        </v-card-text>
                    </v-card>
                    <v-card flat tile class="form">
                        <v-card-title>特定のキュー</v-card-title>
                        <v-card-text>
                            <master-select v-model="form.filter_condition.filter_condition_queues" :select_api="'/api/gc/queue'" />
                        </v-card-text>
                    </v-card>
                    <v-card flat tile class="form">
                        <v-card-title>特定のラップアップコード</v-card-title>
                        <v-card-text>
                            <master-select  v-model="form.filter_condition.filter_condition_wrapupcodes" :select_api="'/api/gc/wrapupcode'" />
                        </v-card-text>
                    </v-card>
                    <v-card flat tile class="form">
                        <v-card-title>特定のアクセス区分</v-card-title>
                        <v-card-text>
                            <master-select  v-model="form.filter_condition.filter_condition_divisions" :select_api="'/api/gc/division'" />
                        </v-card-text>
                    </v-card>
                    <v-card flat tile class="form">
                        <v-card-title>ANIと部分一致</v-card-title>
                        <v-card-text>
                            <tag-select label="gc_ani" v-model="form.filter_condition.filter_condition_ani"></tag-select>
                        </v-card-text>
                    </v-card>
                    <v-card flat tile class="form">
                        <v-card-title>DNISと部分一致</v-card-title>
                        <v-card-text>
                            <tag-select label="gc_dnis" v-model="form.filter_condition.filter_condition_dnis"></tag-select>
                        </v-card-text>
                    </v-card>

                </v-card>
                
            </v-col>
        </v-row>
        </v-form>
      </v-container>

      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="is_show_dialog=false;">キャンセル</v-btn>
          <v-btn v-if="can(settings.Permissions.EDIT_RECORDING_JOB_INFO) && !editmode" text color="primary" @click="onUpdate()">
            {{ editmode ? '更新' : '登録'}}
          </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

    <!-- 日付選択ダイアログ-->
    <dateinterval-dialog ref="dateinterval_dialog" :interval_from="interval_from" @update:interval_from="interval_from = $event"
                          :interval_to="interval_to" @update:interval_to="interval_to = $event" @done="onCloseStartedAtDialog"></dateinterval-dialog>

  </v-main>

</template>

<script>
import axios from 'axios';
import MasterSelect from '../libs/MasterSelect.vue'
import TagSelect from '../libs/TagSelect.vue'
import common from '../../common';
import moment from 'moment';
import my_date_lib from '../../my_date';
import DateintervalDialog from '../libs/DateintervalDialog.vue';
import settings from '../../consts/settings';

const THIS_API = '/api/settings/recording_job_info';
    export default {
        filters: {
            moment_datetime: function (date) {
                return moment(date).format('YYYY-MM-DD HH:mm:ss');
            }
        },        
        components: {
            MasterSelect,
            TagSelect,
            DateintervalDialog,
        },
        computed: {
          settings: function () {
            return settings;
          },
          pager_length: function() {
            let length = 0;
            if (this.rows && this.rows.data) {
              length = this.rows.current_page;
              let count = this.rows.data.length;
              let limit = this.rows.per_page;
              if (count == limit) {
                //  すべて取得できたとき。次のページもあるかもしれないので、ページ数をアップ
                length = length + 1;
              }
            }
            return length;
          },
          interval_title: function() {
            return this.updateStartedAtTitle();
          },


        },

        data () {
            return {
                is_loading: false,
                selected_page: 1,       //  現在選択されているページ（pager用）
                is_show_dialog: false,
                rules: {
                  required: value => !!value || '必須入力',
                  min: v => v.length >= 6 || '6文字以上',
                },

                editmode: false,
                interval_from: null,      //  日付 from　（ダイアログ用）
                interval_to: null,    //  日付 to　（ダイアログ用）

                rows : {},
                form: new Form({
                    id : '',
                    name: '',
                    is_export: true,    //  エクスポートするときtrue(DB上には存在しないフィールド。trueのときはactionにEXPORTをセットして行を追加)
                    is_delete: true,   //  削除のときtrue(DB上には存在しないフィールド。trueのときはactionにDELETEをセットして行を追加。is_exportもtrueのときは一度に２レコード追加されることになる)
                    action: null,     // Jobのアクション\nDELETE または EXPORT");
                    action_date: null,     // アクション実行日時\nEXPORTよりDELETEの方が古い場合は調整される");
                    integration_id: null,     // エクスポート先統合ID\nactionがEXPORTのときのみ設定される。/api/v2/integrationsで取得した行のなかで、integrationType{ id: 'aws-s3-recording-bulk-actions-integration' }が設定されている行のid");
                    include_screen_recordings: true,     // エクスポート時に画面のレコーディングを含めるかどうか\n");
                    job_id: null,     // JOBのID\nPOST /api/v2/recording/jobs で取得できたID");
                    state: null,     // JOBの状態\nFULFILLED, PENDING, READY, PROCESSING, CANCELLED, FAILED");


                    filter_condition: {

            //        is_direction: false,     //  会話の方向\n会話の方向を抽出条件とするときtrue
                        is_inbound: false,       //  インバウンド
                        is_outbound: false,      //  インバウンド
                        filter_condition_users: [], //  対象のユーザー一覧
                        filter_condition_queues: [], //  対象のキュー一覧
                        filter_condition_wrapupcodes: [], //  対象のラップアップコード一覧
                        filter_condition_divisions: [], //  対象のアクセス区分一覧
                        filter_condition_ani: [],   //  対象のANI一覧
                        filter_condition_dnis: [],   //  対象のDNIS一覧
                        duration_time_from: '',    //  接続時間範囲開始秒\n値以上
                        duration_time_to: '',      //  接続時間範囲終了秒\n値以下
                        interval_from: null,     //  通話範囲開始日時\n値以上");
                        interval_to: null,       //  通話範囲終了日時\n値未満");
                    }

                }),
                searchParams: {},
//                selected: '',
//                options: [],


            }
        },
        methods: {
          //  ページネーションクリック
          onPagination() {
            //  チェックははずさないように
            this.getRows(this.selected_page);
          },
          onUpdate() {
            if (this.$refs.form.validate()) {

              if (this.editmode) {
                this.updateRow();

              } else {
                this.createRow();
              }
            }

          },
          getActionTitle(action) {
            if (action == 'EXPORT') {
                return 'エクスポート';
            }
            if (action == 'DELETE') {
                return '削除';
            }
          },



            getRows(page = 1) {
                this.is_loading = true;
                let params = ['page=' + page];

                for(let key in this.searchParams) {
                  params.push(key + '=' + encodeURIComponent(this.searchParams[key]));
                }
                let query = params.join('&');

//                this.$Progress.start();
                
                axios.get(THIS_API + '?' + query).then(({ data }) => {
                    this.rows = data.data;

              //      console.log('get rows', this.rows, data);
                    this.is_loading = false;

                }).catch((data)=> {
                    this.is_loading = false;
                    Swal.fire("Failed!", data.message, "warning");
                });

//                this.$Progress.finish();
            },
            updateRow(){
                this.$Progress.start();
         //       console.log('updaterow', this.form);
                this.form.put(THIS_API + '/'+this.form.id)
                .then((response) => {
                    // success
    //                $('#addNew').modal('hide');
                    this.is_show_dialog = false;
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.getRows();
                }).catch((data)=> {
                    this.$Progress.finish();
                    Swal.fire("Failed!", data.message, "warning");
                });

            },
            editModal(row){
                this.editmode = true;
                this.form.reset();
//                $('#addNew').modal('show');
//                console.log('before fill', this.form);
                this.form.fill(row);
                if (this.form.action == 'EXPORT') {
                    this.form.is_export = true;
                }
                if (this.form.action == 'DELETE') {
                    this.form.is_delete = true;
                }
          //     console.log('fill', this.form, row);
                this.is_show_dialog = true;

                this.resetDurationTime();

            },
            resetDurationTime() {
                //  時刻の初期値がnullだとオブジェクトとして時刻が戻るので、文字列で戻るように
                if (!this.form.filter_condition.duration_time_from) {
                    //  時刻表示が更新されるように
                    Vue.set(this.form.filter_condition, 'duration_time_from', '');
               //     console.log('reset 1');
                }
                if (!this.form.filter_condition.duration_time_to) {
                    //  時刻表示が更新されるように
                    Vue.set(this.form.filter_condition, 'duration_time_to', '');
                //    console.log('reset 2');
                }

            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                this.resetDurationTime();
                //  日付範囲を本日に設定
                this.initSeachStartedAt();

//                console.log('reset fill', this.form);
//                $('#addNew').modal('show');
                this.is_show_dialog = true;
            },

            
            createRow(){
//                console.log('createRow');
                if (!this.form.action_date) {
                    //  実行日時が指定されていないときは現在を設定
                    this.form.action_date = my_date_lib.getDateTimeText(new Date());
                }

                if (this.form.is_export) {
                    //  エクスポートするとき
                    this.form.action = "EXPORT";
                } else if (this.form.is_delete) {
                    //  削除だけのとき
                    this.form.action = "DELETE";
                }
                this.form.post(THIS_API)
                .then((response)=>{
//                    $('#addNew').modal('hide');
                    if (this.form.is_delete && this.form.is_export) {
                        //  エクスポート後に削除のとき
                        this.form.action = "DELETE";
                        this.form.post(THIS_API)
                        .then((response)=>{
                            this.doneCreateRow(response);

                        });
                    } else {
                        this.doneCreateRow(response);

                    }

                }).catch((data)=> {
                    Swal.fire("Failed!", data.message, "warning");
                });
            },
            doneCreateRow(response) {
                this.is_show_dialog = false;
                Toast.fire({
                        icon: 'success',
                        title: response.data.message
                });
                this.getRows();

            },


            onValidWitchChanged(row) {

       //        console.log('onValidWitchChanged', row.is_valid);

                this.updateRowStatus(row);



            },

            updateRowStatus(row){
                this.$Progress.start();
           //     console.log('row', row);
                axios.put(THIS_API + '/'+row.id, row)
                .then((response) => {
              //      console.log('status', response);
                    if (!common.doResponse(response, false)) {
                        //  失敗した場合は再表示
                        this.getRows();
                    }
                    // success
                    this.$Progress.finish();
                }).catch((data)=> {
                    this.$Progress.finish();
                    Swal.fire("Failed!", data.message, "warning");
                    //  失敗した場合は再表示
                    this.getRows();
                });

            },


          onOpenStartedAtDialog() 
          {
              this.interval_from = my_date_lib.parseDate(this.form.filter_condition.interval_from);
              this.interval_to = my_date_lib.parseDate(this.form.filter_condition.interval_to);
              //  ダイアログを開く
//              this.is_show_datetime_picker = true;
              this.$refs.dateinterval_dialog.open();

          },

          onCloseStartedAtDialog() {
              //  取得した範囲(Date型）をform(string型)に設定
              this.setSeachStartedAtFromTo(this.interval_from, this.interval_to);

              this.getRows();

          },
          //  取得した範囲(Date型）をstring型に設定
          setSeachStartedAtFromTo(from, to) {
              this.form.filter_condition.interval_from = my_date_lib.getDateTimeText(from);
              this.form.filter_condition.interval_to = my_date_lib.getDateTimeText(to);
          },
          updateStartedAtTitle() {
            return this.getIntervalTitleFromTo(this.form.filter_condition.interval_from, this.form.filter_condition.interval_to);
          },
          getIntervalTitleFromTo(from, to) {
            let interval_from = my_date_lib.parseDate(from);
            let interval_to = my_date_lib.parseDate(to);
            return my_date_lib.getDateTimeIntervalTextForTitle(interval_from, interval_to);
          },
          getIntervalTitle(row) {
//            console.log('getIntervalTitle', row);
            return this.getIntervalTitleFromTo(row.filter_condition.interval_from, row.filter_condition.interval_to);
          },

          //  日付範囲の初期化（本日）
          initSeachStartedAt() {
            this.setupSearchStartedAt(-1);

          },

          setupSearchStartedAt(add_days)
          {
            //  toは翌日までにする
//            let interval_to = my_date_lib.getNextday();
            //  toは本日
            let interval_to = my_date_lib.getToday();

            //  fromは翌日からの日数（マイナス値）
            let interval_from = my_date_lib.getDayAdd(interval_to, add_days);

            //  取得した範囲(Date型）をsearchParams(string型)に設定
            this.setSeachStartedAtFromTo(interval_from, interval_to);

          },

          getStateTitle(state) {
            const state_title = {
                FULFILLED: '完了', 
                PENDING: '保留中',
                READY: '準備完了',
                PROCESSING: '処理中',
                CANCELLED: 'キャンセル', 
                FAILED: '失敗',
            };
            return state_title[state] !== undefined ? state_title[state] : '';

          },

          //  キャンセル可能な状態かどうかチェック
          canCancel(row) {
            let state = row.state;
            if (state == 'PENDING' || state == 'READY' || state == 'PROCESSING') {
                return true;
            } else {
                return false;
            }
          },
          onCancel(row) {
                Swal.fire({
                    title: 'キャンセルしてよろしいですか?',
                    text: "この操作は取り消せません",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'キャンセル'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                            this.doCancel(row);
                         }
                    });
          },
          doCancel(row) {

            this.is_loading = true;

            axios.put(THIS_API + '_cancel/'+row.id, null) 
            .then((response) => {
                this.is_loading = false;
                common.doResponse(response, false);

                //  再表示
                this.getRows();

            }).catch((data)=> {
                this.is_loading = false;
                Swal.fire("Failed!", data.message, "warning");

            });

          },


        },
        mounted() {
    //        console.log('Component mounted.')
        },
        created() {
            //  日付範囲を本日に設定
            this.initSeachStartedAt();

            this.getRows();
        }
    }
</script>
