export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
//    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/groups', component: require('./components/Groups.vue').default },
    { path: '/roles', component: require('./components/Roles.vue').default },
//    { path: '/products', component: require('./components/product/Products.vue').default },
//    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
//    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default },
    { path: '/contacts', component: require('./components/contact/Contacts.vue').default, name: 'contacts' },
    // /contacts/[conversation_id]/2  というパスのときはメニュー表示しないで通話テキストのみ表示する。genesys cloudないでiframe表示を想定
    { path: '/contacts/:id/:id_type', component: require('./components/contact/Detail.vue').default, name: 'contact_detail_with_id_type', props: true },
    { path: '/contacts/:id', component: require('./components/contact/Detail.vue').default, name: 'contact_detail', props: true },
    { path: '/settings/filter_setting', component: require('./components/settings/FilterSetting.vue').default },
    { path: '/settings/system_setting', component: require('./components/settings/SystemSetting.vue').default },
    { path: '/settings/recording_job_info', component: require('./components/settings/RecordingJobInfo.vue').default },
    { path: '/gc_settings/queues', component: require('./components/gcSettings/Queues.vue').default },
    { path: '/gc_settings/wrapupcodes', component: require('./components/gcSettings/Wrapupcodes.vue').default },
    { path: '/gc_settings/users', component: require('./components/gcSettings/Users.vue').default },
    { path: '/gc_settings/divisions', component: require('./components/gcSettings/Divisions.vue').default },

    //  TODO: 以下、削除すること。　使っていないので
//    { path: '/settings/conditions', component: require('./components/settings/Conditions.vue').default },
];
