<template>
                <tr>
                    <td>
                        <vue-select label="name" code="week_index" :clearable="false" :options="week_options" v-model="week" :filterable="true" style="width:8em;"></vue-select>
                    </td>
                    <td>
                        <vue-timepicker v-model="value.from" input-width="5em"  hide-clear-button>
                        </vue-timepicker>
                    </td>
                    <td>
                        <vue-timepicker v-model="value.to" input-width="5em"  hide-clear-button>
                        </vue-timepicker>

                    </td>
                    <td>
                        <v-btn small icon @click="onAdd"><v-icon  color="primary">mdi-plus-circle</v-icon></v-btn>
                        <v-btn small icon @click="onRemove"><v-icon  color="primary">mdi-minus-circle</v-icon></v-btn>
                    </td>
                </tr>

</template>

<script>
    export default {
        name: 'time-set',
        props: {
            value: {
                type: Object,
            },
        },
        computed: {
            week: {
                get() {
                    return {
                        name: this.getName(this.value.week_index),
                        week_index: this.value.week_index,
                    };
                },
                set(newValue) {
                    this.value.week_index = newValue.week_index;
                },
            },
        },

        data () {
            return {
                week_options: [
                    {
                        name: '月曜日',
                        week_index: 1,
                    },
                    {
                        name: '火曜日',
                        week_index: 2,
                    },
                    {
                        name: '水曜日',
                        week_index: 3,
                    },
                    {
                        name: '木曜日',
                        week_index: 4,
                    },
                    {
                        name: '金曜日',
                        week_index: 5,
                    },
                    {
                        name: '土曜日',
                        week_index: 6,
                    },
                    {
                        name: '日曜日',
                        week_index: 0,
                    },
                ],
            }
        },
        methods: {
            getName(week_index) {
                let found_week = this.week_options.find(elm => elm.week_index == week_index);
                if (found_week) {
                    return found_week.name;
                } else {
                    return null;
                }
            },
            onAdd() {
                this.$emit('add');
            },
            onRemove() {
                this.$emit('remove');
            },

        },
        mounted() {
        },
        created() {
        }
    }
</script>
