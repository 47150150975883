<style scoped lang="scss">
.card-detail {
    margin: 16px;
    .label {
        font-weight: 700;
    }
}
.balloon_l,
.balloon_r {
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.balloon_r {
  justify-content: flex-end;
}
.face_icon img {
  width: 80px;
  height: auto;
}
.balloon_r .face_icon {
  margin-left: 25px;
}
.balloon_l .face_icon {
  margin-right: 25px;
}
.says {
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 10px;
  border-radius: 12px;
  background: #8ee7b6;
  box-sizing: border-box;
  margin: 0 !important;
  line-height: 1.5;
  /*   align-items: center; */
}
.says p {
  margin: 8px 0 0 !important;
}
.says p:first-child {
  margin-top: 0 !important;
}
.says:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  margin-top: -3px;
}
.balloon_l .says:after {
  left: -26px;
  border-right: 22px solid #8ee7b6;
}
.balloon_r .says:after {
  right: -26px;
  border-left: 22px solid #8ee7b6;
}
/*
.balloon_r .timestamp {
    float: left;
    position: relative;
    left: calc(-300px - 4em);
}
*/
.balloon_r .timestamp {
  order: 1 !important;
}
.balloon_r .says {
  order: 2 !important;
}
.balloon_r .face_icon {
  order: 3 !important;
}
.importance {
    font-weight: bold;
    font-style: italic;
}

/*====================
スマホを基本設計にする
 - 〜479px：スマートフォン縦
 - 480px〜599px：スマートフォン横
 - 600px〜959px：タブレット
 - 960px〜1279px：小型PC
 - 1280px〜：大型PC
====================*/
 
/* 〜479px：SP縦
------------------------------ */
 
 
/* 480px〜599px：SP横
------------------------------ */
/*
@media screen and (min-width:480px) and (max-width:599px) { 
}
*/
 
/* 600px〜959px：タブレット
------------------------------ */
@media screen and (min-width:600px) and (max-width:959px) {
    .says {
        max-width: 600px;
    }
}
 
/* 960px〜1279px：小型PC
------------------------------ */
@media screen and (min-width:960px) and (max-width:1279px) {
    .says {
        max-width: 960px;
    }
}
 
/* 1280px〜：大型PC
------------------------------ */
@media screen and (min-width:1280px) {
    .says {
        max-width: 1280px;
    }
}
</style>
<template>
  <v-main>
    <v-toolbar v-if="!is_show_text_only" elevation="1" height="50">
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ma-0">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              href="javascript:void(0)"
              :disabled="item.disabled"
              @click="$router.push(item.href)"
          >
          {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

    </v-toolbar>

    <v-card v-if="row && !is_show_text_only" class="card-detail" outlined :loading="is_loading">
            <v-toolbar dense  elevation="1" color="grey lighten-5">
                <v-toolbar-title>基本情報</v-toolbar-title>
                <v-spacer></v-spacer>
                <span>{{row.conversation_id}}</span>
                <v-btn icon @click="copyConversationId()" >
                    <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>

              <v-row>
                <v-col cols="12" xl="3" lg="4" md="6" xs="12">
                        <v-row>
                            <v-col cols="5">
                                <label class="label">日付</label>
                            </v-col>
                            <v-col cols="7">
                                <span class="value">{{row.started_at}}</span>
                            </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" xl="3" lg="4" md="6" xs="12">
                        <v-row>
                            <v-col cols="5">
                                <label class="label">方向</label>
                            </v-col>
                            <v-col cols="7">
                                <span class="value">{{directionText(row.initial_direction)}}</span>
                            </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" xl="3" lg="4" md="6" xs="12">
                        <v-row>
                            <v-col cols="5">
                                <label class="label">ANI</label>
                            </v-col>
                            <v-col cols="7">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <span class="value" v-bind="attrs" v-on="on">{{parseDniAni(row.ani_normalized)}}</span>
                                </template>
                                <span>{{row.ani_normalized}}</span>
                              </v-tooltip>
                            </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" xl="3" lg="4" md="6" xs="12">
                        <v-row>
                            <v-col cols="5">
                                <label class="label">ユーザー</label>
                            </v-col>
                            <v-col cols="7">
                                <span class="value">{{getNames(row.contact_users)}}</span>
                            </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" xl="3" lg="4" md="6" xs="12">
                        <v-row>
                            <v-col cols="5">
                                <label class="label">会話接続時間</label>
                            </v-col>
                            <v-col cols="7">
                                <span class="value">{{timeSpan(row.started_at, row.ended_at)}}</span>
                            </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" xl="3" lg="4" md="6" xs="12">
                        <v-row>
                            <v-col cols="5">
                                <label class="label">DNIS</label>
                            </v-col>
                            <v-col cols="7">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <span class="value" v-bind="attrs" v-on="on">{{parseDniAni(row.dnis_normalized)}}</span>
                                </template>
                                <span>{{row.dnis_normalized}}</span>
                              </v-tooltip>

                            </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" xl="3" lg="4" md="6" xs="12">
                        <v-row>
                            <v-col cols="5">
                                <label class="label">キュー</label>
                            </v-col>
                            <v-col cols="7">
                                <span class="value">{{getNames(row.contact_queues)}}</span>
                            </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" xl="3" lg="4" md="6" xs="12">
                        <v-row>
                            <v-col cols="5">
                                <label class="label">ラップアップコード</label>
                            </v-col>
                            <v-col cols="7">
                                <span class="value">{{getNames(row.contact_wrapupcodes)}}</span>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>


                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            outlined
                            label="メモ"
                            auto-grow
                            row-height="10"
                             v-model="row.memo"
                              @change="onSave(row)"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row v-if="row.destination_file_path">
                    <v-col cols="auto">
                        <audio :src="'/api/aws/s3?path=' + encodeURI(row.destination_file_path)" controls></audio>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
        <v-card class="card-detail"  v-if="row && row.contact_texts">
            <v-toolbar dense  elevation="1" color="grey lighten-5">
                <v-toolbar-title>通話テキスト</v-toolbar-title>
                <v-spacer></v-spacer>

                <template v-if="isSummarizedRow()">
                    <!-- ツールバー右側のボタン-->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on" @click="is_show_threshold_dialog=true">
                                <v-icon small color="grey darken-4">mdi-pencil-outline</v-icon>
                                <v-subheader class="pr-0">タイプ：</v-subheader><span>{{threshold_type_text}}</span>
                                <v-subheader class="pr-0">閾値：</v-subheader><span>{{threshold_value}}</span>
                            </v-btn>
                        </template>
                        <span>発話を絞り込むタイプ・閾値を編集します</span>
                    </v-tooltip>

                    <v-switch
                      class="mx-3"
                        v-model="is_summary"
                        label="要約表示"
                        hide-details
                    ></v-switch>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-switch
                                    class="mx-3"
                                    v-model="is_refine"
                                    label="リファイン"
                                    hide-details
                                    @change="calcIfSummarizedText()"
                                ></v-switch>

                            </div>

                        </template>
                        <span>可読性向上したテキストを表示します</span>
                    </v-tooltip>

                </template>             

                <v-btn-toggle
                    v-model="selected_avatar"
                    color="primary"
                    dense
                    @change="onChangeAvatar"
                    >
                    <template v-for="(info, index) in avatar_infos">
                        <v-tooltip bottom :key="index">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="!info.disabled" small  :value="index" text  v-bind="attrs" v-on="on">
                                <v-avatar size="20" :color="info.color">
                                    <span class="white--text">
                                        {{ info.label }}
                                    </span>
                                </v-avatar>
                            </v-btn>

                            </template>
                            <span>{{info.text}}</span>
                        </v-tooltip>

                    </template>
        
                </v-btn-toggle>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="gray"  @click="switchAvatar()"  v-bind="attrs" v-on="on">
                        <v-icon small>mdi-account-switch-outline</v-icon>
                    </v-btn>
                    </template>
                    <span>オペレーター/カスタマーの表示切り替え</span>
                </v-tooltip>


            </v-toolbar>

            <v-simple-table wrap  justify-end>
                <tr>
                <!-- メイン -->
                <td style="padding:0.5em;">
                    <v-row :class="{ 'grey lighten-3': !is_summarized(contact_text) }" v-for="(contact_text,i) in row.contact_texts" :key="i" dense>
                        <v-col v-show="filter(contact_text)">
                            <div :class="isLeft(contact_text.channel_id) ? 'balloon_l' : 'balloon_r'">
                                <div class="face_icon">
                                    <v-avatar :color="getAvatarColorByChannelId(contact_text.channel_id)">
                                        <span class="white--text">
                                            {{ getAvatarLabelByChannelId(contact_text.channel_id) }}
                                        </span>
                                    </v-avatar>
                                </div>
                                <p class="says" v-html="summarized_text(contact_text)">
                                </p>
                                <span class="timestamp">{{ contact_text.started_at | moment}}</span>
                                <span class="importance">({{contact_text.importance}})</span>

                            </div>
                        </v-col>                
                    </v-row>

                </td>
                <keyword :row="row"></keyword>
            </tr>
            </v-simple-table>
        </v-card>



<!-- 閾値設定 -->
    <v-dialog
        v-model="is_show_threshold_dialog"
        width="500"
        hide-overlay
      >
  
        <v-card>
  
          <v-card-text class="pt-4">
            <v-select
                v-model="selected_threshold_type"
                :items="threshold_types"
                label="タイプ"
                @change="calcIfSummarizedText()"
            ></v-select>
            <v-subheader  v-if="threshold_value_info" class="pl-0">
                {{threshold_value_info.subheader}}
            </v-subheader>
            <v-slider v-if="threshold_value_info"
                v-model="threshold_value_info.value"
                :label="threshold_value_info.label"
                :min="threshold_value_info.min"
                :max="threshold_value_info.max"
                :step="threshold_value_info.step"
                @change="calcIfSummarizedText()"
            >
                <template v-slot:append>
                  <v-text-field
                    v-model="threshold_value_info.value"
                    class="mt-0 pt-0"
                    type="number"
                    style="width: 60px"
                    :step="threshold_value_info.step"
                    @input="calcIfSummarizedText()"
                  ></v-text-field>
                </template>
            </v-slider>

          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="is_show_threshold_dialog = false"
            >
              閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-main>
</template>

<style scoped>
.direct-chat-messages {
  height: 100%;
}

</style>


<script>
import moment from 'moment';
import common from '../../common';
import Keyword from './Keyword.vue';
import sanitizeHTML from 'sanitize-html'
const THIS_API = '/api/contact';
    export default {
        components: { Keyword },
        filters: {
            moment: function (date) {
                return moment(date).format('HH:mm:ss');
            }
        },
        //  id_typeが1または2のときidはconversation_id。それ以外はidはcontact_id。id_typeが2のときはメニューなど非表示で通話テキストのみ表示（リアルタイムでgenesyscloudないでの表示を想定）
        props: ['id', 'id_type'],

        watch: {
            id: function(new_value, old_value)  {
            //   console.log('id updated', this.id);
                this.getRow(this.id);

            },
        },
        computed: {
            is_show_text_only() {
                return this.id_type == 2 ? true : false;
            },
            is_conversation_id() {
                return (this.id_type == 1 || this.id_type == 2) ? true : false;
            },
            threshold_value_info() {
                if (this.selected_threshold_type !== null && this.selected_threshold_type != undefined && this.selected_threshold_type > 0) {
                    let index = this.selected_threshold_type - 1;
                    return this.threshold_value_infos[index];
                } else {
                    return null;
                }
            },
            threshold_value() {
                return this.threshold_value_infos[this.selected_threshold_type-1].value;
            },
            threshold_type_text() {
                return this.threshold_types[this.selected_threshold_type-1].text;
            },
            //  選択されているキーワードの配列
            selectedKeywords() {
              return this.row.contact_keywords.filter(keyword => keyword.is_selected);
            },
        },
        data () {
            return {
                breadcrumbs: [
                    {
                        text: '通話一覧',
                        disabled: false,
                        exact: true,
                        href: '/contacts',
                    },
                    {
                        text: '通話詳細',
                        disabled: true,
                        href: null,
                    },
                ],
                is_loading: false,
                avatar_infos: [
                    {
                        id: 1,
                        color: 'pink lighten-3',
                        label: 'O',
                        text: 'オペレーター',
                        disabled: false,
                    },
                    {
                        id: 2,
                        color: 'blue darken-1',
                        label: 'C',
                        text: 'カスタマー',
                        disabled: false,
                    },
                    {
                        id: 3,
                        color: 'orange darken-1',
                        label: 'O2',
                        text: 'オペレーター2',
                        disabled: true,
                    },
                    {
                        id: 4,
                        color: 'teal darken-1',
                        label: 'O3',
                        text: 'オペレーター3',
                        disabled: true,
                    },
                ],
                selected_avatar: null,

                is_show_threshold_dialog: false,
                threshold_types: [
                    { text: '重要度' , value: 1    },
                    { text: '発話数最大値' , value: 2   },
                    { text: '文字数最大値' , value: 3   },
                ],
                selected_threshold_type: 1,

                threshold_value_infos: [
                    { label: '値(0.0〜1.0)', min:0, max:1.0, step:0.01, value: 0.5, subheader: '重要度の絶対値で表示を絞ります' },
                    { label: '閾値(0個〜)', min:0, max:372, step:1, value: 10, subheader: '指定した発話数以内で重要な発話を表示します' },
                    { label: '閾値(0文字〜)', min:0, max:4085, step:10, value: 100, subheader: '指定した文字数以内で重要な発話を表示します' },
                ],




                row: null,
                editmode: false,
                searchParams: {
                  call_direction: '',
                  ani: '',
                  dnis: '',
                  digest: '',
                  started_at: '',
                },

                rows : {},

                users : {},
                form: new Form({
                    id : '',
                    type : '',
                    name: '',
                    email: '',
                    password: '',
                    email_verified_at: '',
                }),
                is_summary: false,
                is_refine: true,

                summarized_threshold: 0.5,
            }
        },
        methods: {
          //  カスタマーとオペレーターをスイッチ
          switchAvatar() {
            let id0 = this.avatar_infos[0].id;
            let id1 = this.avatar_infos[1].id;
            this.avatar_infos[0].id = id1;
            this.avatar_infos[1].id = id0;

          },
            breakpoint() {
                return this.$vuetify.breakpoint.name + '(' + this.$vuetify.breakpoint.width + 'x' + this.$vuetify.breakpoint.height + ')';
            },
            getNames(array) {
                let names = [];
                if (array != null && array.length > 0) {
                    names = array.map(elm => elm.name).filter(elm => elm != null);
                }
                if (names.length > 0) {
                    return names.join(' / ');
                } else {
                    return '-';
                }
            },
            //  channel id からアバター色を取得
            getAvatarInfoByChannelId(id) {
                return this.avatar_infos.find(elm => elm.id == id);
            },
            getAvatarColorByChannelId(id) {
                let info = this.getAvatarInfoByChannelId(id);
                return info ? info.color : 'black';
            },
            getAvatarLabelByChannelId(id) {
                let info = this.getAvatarInfoByChannelId(id);
                return info ? info.label : '?';

            },
            isLeft(channel_id) {
                return (channel_id - 1) % 2 == 0;
            },
            getFloatClassByChannelId(channel_id) {
                return channel_id % 2 == 0 ? 'float-right' : 'float-left';
            },
            onChangeAvatar() {

            },
            //  表示可能な通話か
            filter(contact_text) {
                let is_show = contact_text.text ? true : false;

                if (is_show && this.is_summary) {
                    //  要約するとき
                    //  要約対象かどうか調べる
                    is_show = this.is_summarized(contact_text);
                }
                if (is_show && this.selected_avatar !== null && this.selected_avatar !== undefined) {
                  //  カスタマーかエージェントか
                    let info = this.avatar_infos[this.selected_avatar];
                    if (contact_text.channel_id != info.id) {
                        is_show = false;
                    }
                }
                if (is_show && this.selectedKeywords.length > 0) {
                  //  キーワードが選択されているときは、そのキーワードを含む場合のみ表示
                  is_show = false;
                  for (let index = 0; index < this.selectedKeywords.length; index++) {
                    let keyword = this.selectedKeywords[index];
                    let start = contact_text.text.indexOf(keyword.text);
                    if (start > -1) {
                        //  キーワードと一致したので表示OK
                        is_show = true;
                        break;
                    }
                  }
                }
                return is_show;
            },
            getRow(id) {
                return new Promise((resolve, reject) => {
                    if (!this.row || this.row.id != id || this.is_conversation_id) {        //  conversation_idのときはリアルタイムのときなので同じIDで繰り返し取得する
//                        this.$Progress.start();
                        this.is_loading = true;
                        
                        axios.get(THIS_API + '/' + id + '?id_type=' + this.id_type).then(({ data }) => {

                            this.row = data.data;

                            this.calcIfSummarizedText();

                    //        console.log('get row', this.id_type, this.row, data);
//                            this.$Progress.finish();
                            this.is_loading = false;

                            resolve(this.row);
                        }).catch((data)=> {
                            this.is_loading = false;
                            Swal.fire("Failed!", data.message, "warning");
                        });

                    }

                });
            },
            //  要約テキストかどうか 
            calcIfSummarizedText() {
                let threshold_value_info = this.threshold_value_info;
                if (threshold_value_info) {
                    this.calcIfSummarizedTextWithThreashold(threshold_value_info.value, this.selected_threshold_type);
                }
            },
            calcIfSummarizedTextWithThreashold(threshold, threshold_type) {

                if (!this.row || !this.row.contact_texts) {
                    return;
                }
                let length = this.row.contact_texts.length;
                if (length == 0) {
                    return;
                }

                if (threshold_type == 1) {
                  //  重要度による要約のとき

                    this.row.contact_texts = this.row.contact_texts.map (contact_text => {
                        contact_text.is_summarized = contact_text.importance >= threshold ? true : false;
                        return contact_text;

                    });

                } else if (threshold_type == 2 || threshold_type == 3) {

                    //  重要度以外の要約のとき

                    //  要約状態を一旦リセット
                    this.row.contact_texts = this.row.contact_texts.map (contact_text => {
                        contact_text.is_summarized = false;
                        return contact_text;

                    });

                    let rest_count = threshold;
                    let ranking = 1;

                    //  ランキングの最大値を取得
                    let max_ranking = 1;
                    for (let i =0; i < length; i++) {
                        let contact_text = this.row.contact_texts[i];
                        max_ranking = Math.max(contact_text.ranking, max_ranking);
                    }


                    for (ranking = 1; ranking <= max_ranking && rest_count > 0; ranking++) {
                        for (let i =0; i < length; i++) {
                            let contact_text = this.row.contact_texts[i];

                            if (ranking == contact_text.ranking && !contact_text.is_summarized) {


                                //  ランキングの高いテキストから順に要約対象にする
                                contact_text.is_summarized = true;

                                if (threshold_type == 2) {
                                    //  発話数による要約のとき
                                    rest_count--;       //  カウントを一つ消費
                                } else {
                                    //  文字数による要約のとき
                                    let char_length = (this.is_refine && contact_text.refined) ? contact_text.char_length_refined : contact_text.char_length;
                                    rest_count -= char_length; //  文字数を消費
                                    if (rest_count < 0) {
                                        //  マイナスになってしまうときは文字数をこえているので要約対象からはずす
                                        contact_text.is_summarized = false;
                                    }
                                }

                                this.row.contact_texts[i] = contact_text;
                                if (rest_count <= 0) {
                                    break;
                                }
                            }
                        }

                    }
                }




            },

            directionText(direction) {
              return common.directionText(direction);
            },
            timeSpan(start_text, end_text) {
                let start = new Date(start_text);
                let end = new Date(end_text);
                let diffTime = end.getTime() - start.getTime();
                let diffSec = Math.floor(diffTime / 1000 );
                let min = Math.floor(diffSec / 60);
                let sec = diffSec % 60;

                let result = '';
                if (min > 0) {
                    result = min + '分';
                }
                return result = result + sec + '秒';
            },

            //  要約テキストかどうか
            is_summarized(contact_text) {
                return contact_text.is_summarized;
                /*
                let importance = contact_text.importance;
                let is_summarized = true;
                if (importance !== null) {

                    let threshold_value_info = this.threshold_value_info;
                    if (threshold_value_info) {
                        is_summarized = importance >= threshold_value_info.value ? true : false;
                    }
                }
                return is_summarized;
*/
            },
            //  要約状態のときはrefindeがあればrefined。それ以外はtextを返す
            summarized_text(contract_text) {
                let text = (this.is_refine && contract_text.refined) ? contract_text.refined : contract_text.text;
                text = sanitizeHTML(text);
                return this.emphasis_keyword(text, this.row.contact_keywords);
            },

            //  キーワード部分を強調表示
            emphasis_keyword(text, keywords) {

                if (keywords && keywords.length > 0) {
                    keywords.forEach(keyword => {
                        let start = text.indexOf(keyword.text);
                        if (start > -1) {
                            let end = start + keyword.text.length;
                            //  キーワードと一致したので強調表示
                            text = text.substring(0, start) + "<span class='red--text'>" + keyword.text + "</span>" + text.substring(end);
                        }
                        
                    });

                }
                return text;
            },

            onSave(row) {
                this.is_loading = true;
                axios.put(THIS_API + '/' + row.id, row).then((data) => {
                    this.is_loading = false;
                    common.doResponse(data);

                });

            },
            //  要約済みかどうか
            isSummarizedRow() {
                return (this.row && this.row['summarized_credit_type']) ? true : false;
            },

            //  シーケンス終了かどうか
            isEndOfSequence() {
                return (this.row && this.row['end_of_sequence'] == true) ? true : false;
            },
            parseDniAni(text) {
              return common.parseDnisAni(text);

            },
            //  インタラクションIDをクリップボードにコピー
            copyConversationId() {
              this.$copyText(this.row.conversation_id).then(function (e) {
                Toast.fire({
                  icon: 'success',
                  title: 'インタラクションIDをクリップボードにコピーしました',
                });
              }, function (e) {
                  alert('Can not copy')
                  console.log(e)
              });
            },

        },
        mounted() {
       //     console.log('detail Component mounted.')
        },
        created() {
            console.log('created', this.$route.params.id, this.id, this.row );
            this.getRow(this.id).then((row) => {
                if  (this.is_conversation_id && !(this.isEndOfSequence() || this.isSummarizedRow())) {
                    //  conversation_idのときはリアルタイムのときなので定期的に取得。ただし、endフラグがセットされたか、要約済みのときは定期取得しない
                    this.interval_id = setInterval(() => { 
                        this.getRow(this.id).then(() => {
                            if (this.isEndOfSequence() || this.isSummarizedRow()) {
                                //  すべてのシーケンスを受信したのでポーリングを止める
                                clearInterval(this.interval_id);
                                this.interval_id = null;
                                console.log('停止');
                            }

                        });
                    }, 5000);
                }
            });


        },
        beforeDestroy () {
       //     console.log('clearInterval');
            if (this.interval_id) {
                clearInterval(this.interval_id);
            }
        }        
    }
</script>
