<template>
  <v-main>
        <v-card   flat outlined tile class="ma-3">
            <v-toolbar dense  elevation="1">
                <v-toolbar-title  class="text-subtitle-2">転送設定</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn  v-if="can(settings.Permissions.EDIT_SYSTEM_SETTING)" text color="primary"  @click="updateRow">
                    保存
                </v-btn>
            </v-toolbar>
                <v-card-text>
                    <v-text-field label="音声ファイル転送先のs3バケット名(ap-northeast-1リージョンのs3バケットを指定してください)" placeholder="s3バケット名" v-model="form.transfer_s3_backet_name" 
                     clearable></v-text-field>


                </v-card-text>
        </v-card>

  </v-main>
</template>
<style scoped>
label.radio {
    margin-right: 2em;
}
</style>

<script>
import axios from 'axios';
import settings from '../../consts/settings';

const THIS_API = '/api/settings/system_setting';
    export default {
        computed: {
          settings: function () {
            return settings;
          },
        },


        data () {
            return {
                rules: {
                  required: value => !!value || '必須入力',
                },

                form: new Form({
                    id: 1,
                    transfer_s3_backet_name: null,              //  転送先s3バケット名\ntransfer_typeが2のときに有効");


                }),
                filter_conditions: [],

            }
        },
        methods: {
            getRow() {

                this.$Progress.start();
                
                axios.get(THIS_API + '/1').then(({ data }) => {
                    if (data.success) {

                        let row = data.data;

                        this.form.fill(row);
                    }

                    console.log('get row', this.form, data);
                    this.$Progress.finish();
                }).catch((data)=> {
                    this.$Progress.finish();
                    Swal.fire("Failed!", data.message, "warning");

                });

            },
            updateRow(){
                this.$Progress.start();
                this.form.put(THIS_API + '/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.getRow();
                }).catch((data)=> {
                    this.$Progress.finish();
                    Swal.fire("Failed!", data.message, "warning");
                });

            },




        },
        mounted() {
        },
        created() {
            this.getRow();
        }
    }
</script>
