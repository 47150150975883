<template>
    <!--  v-modelは、nameとidではなく、nameとvalueのときもある。code="id"を指定しているが、idが存在しない場合はvalueをみてくれているみたい。現状どちらもうまく動いている。-->

     <v-autocomplete
          v-model="inputedValue"
          :items="options"
          :loading="is_loading"
          :search-input.sync="search"
          hide-no-data
          hide-selected
          item-text="name"
          item-value="id"
          :label="label"
          :placeholder="label"
          return-object
          clearable
          @change="onChange"
        ></v-autocomplete>

</template>

<script>
    export default {
        name: 'master-select2',
        props: {
            value: {
                type: Object,
            },
            select_api: {
                type: String,
            },
            label: {
                type: String,
            },
        },
        computed: {
            inputedValue: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    this.$emit("input", newValue);
                },
            },
        },
        watch: {
            search (val) {
                this.onSearch(val);

            },
            value (val) {
                console.log('new value', val);

            },
        },

        data () {
            return {
                options: [],
                is_loading: false,
                search: null,
            }
        },
        methods: {
            onChange(value) {
                this.$emit("change", value);
            },

            onSearch(input_text) {
                if (this.is_loading) return;

                let select_api = this.select_api;

                let params = [
                    'page=1',
                    'limit=50',
                ];
                if (input_text) {
                    params.push('name=' + encodeURIComponent(input_text));
                }
                let query = params.join('&');

                axios.get(select_api + '?' + query)
                .then(({ data }) => {

                    if (data.success) {
                        this.options = data.data.data;
                    }
                })
                .finally(() => (this.is_loading = false));

            },

        },
        mounted() {
        },
        created() {
        }
    }
</script>
