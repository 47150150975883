<template>
    <span>
        <div v-if="title" class="d-flex justify-center mt-3">
                <span class="text-h6">{{title}}</span>
        </div>

        <v-date-picker 
            v-model="picker_date"
            color="primary"
            no-title
            locale="jp-ja"
            :day-format="date => new Date(date).getDate()"
            ></v-date-picker>
            <v-card
                class="d-flex justify-center mb-6"
                flat
                tile
            >
                <vue-timepicker 
                    hide-dropdown
                    manual-input
                    v-model="picker_time"
                    input-width="6em"
                >
                </vue-timepicker>
                <v-subheader>{{picker_date_time_text}}</v-subheader>
            </v-card>

    </span>

</template>

<script>
import my_date_lib from '../../my_date';

    export default {
        name: 'my-datetimepicker',

        //  親のv-modelとのインターフェース　(value / inputは別の名前にしてもいい。デフォルトで value / inputなので今回の場合はmodel指定しなくてもいい)
        model: {
            prop: 'value',    // v-modelの値をvalueで取得
            event: 'input',   // this.$emit('input', '更新値')で更新
        },        
        props: {
            //  親のv-modelの値を受け取る。model:propで指定した名前にすること
            //  日時
            value: {
                type: Date,
                default: Date.now(),
            },
            title: {
                type: String
            },
        },
        computed: {
            picker_date: {
                get() {
                    if (!this.value) {
                        return null;
                    }
                    return my_date_lib.getDateText(this.value);
                },
                set(newValue) {
                    //  
                    let new_value = my_date_lib.mergeDateAndTime(newValue, this.value);
                    this.$emit('input', new_value); //  

                },
            },
            picker_time: {
                get() {
                    if (!this.value) {
                        return '00:00';
                    }
                    return my_date_lib.getTimeText(this.value);
                },
                set(newValue) {
                    console.log('time', newValue);
                    //  
                    let new_value = my_date_lib.mergeDateAndTime(this.value, newValue);
                    this.$emit('input', new_value); //  

                },
            },
            picker_date_time_text: function() {
                if (!this.value) {
                    return '';
                }
                console.log('picker_date_time_text', this.value);
                return my_date_lib.getDateTimeText(this.value, '/', false);

            }
        },

        data () {
            return {
            }
        },
        methods: {

        },
        mounted() {
        },
        created() {
        }
    }
</script>
