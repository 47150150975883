
function doResponse(response, is_show_success = true) {
    let result;
//    console.log('doResponse', response);
//    console.log('doResponse success', is_show_success, response.data.success);
    if(response.data.success){
      if (is_show_success) {
        // success
        Toast.fire({
          icon: 'success',
          title: response.data.message
        });

      }
      result = true;

    } else {
      Toast.fire({
          icon: 'error',
          showConfirmButton: true,
          timer: 0,
          title: response.data.message
      });
      result = false;
    }
    return result;
}

function doResponseFire(response, successTitle, failTitle) {
    let result;
    if(response.data.success){
        // success
        Swal.fire(successTitle, response.data.message, 'success');
        result = true;

    } else {
        Swal.fire(failTitle, response.data.message, "warning");
        result = false;
    }
    return result;
}

function confirmDelete(okFunc, params, title = null, buttonTitle = null) {
  if (!title) {
    title = '本当に削除してよろしいですか？';
  }
  if (!buttonTitle) {
    buttonTitle = '削除'
  }
  Swal.fire({
    title: title,
    text: "この操作を取り消すことはできません",
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: buttonTitle,
    cancelButtonText: 'キャンセル'
    }).then((result) => {

        if (result.value) {
          okFunc(params);
        }
    })

}
function confirmOK(title, text, okFunc, params, confirmButtonText='OK') {
  Swal.fire({
    title: title,
    text: text,
    showCancelButton: false,
//    confirmButtonColor: '#d33',
    confirmButtonText: confirmButtonText,
    }).then((result) => {

        if (result.value) {
          if (okFunc) {
            okFunc(params);
          }
        }
    })

}

function confirmYesNo(title, text, okFunc, params, confirmButtonText='Yes', cancelButtonText='キャンセル') {
  Swal.fire({
    title: title,
    text: text,
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText
    }).then((result) => {

        if (result.value) {
          if (okFunc) {
            okFunc(params);
          }
        }
    })

}


function toastError(message) {
  Toast.fire({
    icon: 'error',
    showConfirmButton: true,
    timer: 0,
    title: message
  });
}



function toShiftJis(csv) {
  csv = new Encoding.stringToCode(csv)
  csv = Encoding.convert( csv, 'SJIS');
  csv = new Uint8Array( csv );
  return csv;
}


function createIdNameArrayTypeValues(array) {

  let typeValues =[];
//  typeValues.push({value: null, name: ''});   //  取り消し用

  for (let id in array) {
    typeValues.push({value: id, name: array[id]});
  }
  return typeValues;

}

//  ファイルの先頭行を取得
function readHeader(fileInfo) {
  return new Promise((resolve, reject) => {
    let file_reader = new FileReader();

    // ファイルの読み込みを行ったら実行
    file_reader.addEventListener('load', function(e) {
      var array = new Uint8Array(e.target.result);

      let encoding = Encoding.detect(array);
//      console.log('readHeader', encoding);
    // 文字コードを取得
    switch (encoding) {
      case 'UTF16':
          // 16ビット符号なし整数値配列と見なす
          array = new Uint16Array(e.target.result);
          break;
      case 'UTF32':
          // 32ビット符号なし整数値配列と見なす
          array = new Uint32Array(e.target.result);
          break;
      }
      if (encoding == 'UNICODE') {
        //  何故かSJISなのにUNICODEと検出されるときがある。
        encoding = 'SJIS';
      }
      var unicodeArray = Encoding.convert(array, 'UNICODE', encoding);
      // Unicodeの数値配列を文字列に変換
      var text = Encoding.codeToString(unicodeArray);
  
//      console.log(text); // 結果
  
        let header = null;
        let lines = text.split(/\r\n|\n/);
        if (lines.length > 0) {
            header = lines[0];
        }
        resolve(header);
      });

    file_reader.readAsArrayBuffer(fileInfo);

  });
}
function baseName(str)
{
   var base = new String(str).substring(str.lastIndexOf('/') + 1); 
    if(base.lastIndexOf(".") != -1)       
        base = base.substring(0, base.lastIndexOf("."));
   return base;
}

///  csv文字列から配列を生成
function makeArrayFromCSVText(csvText, isExistHeader)
{
  let rows = csvText.split('\n');
  for (let i= isExistHeader ? 1 : 0; i < rows.length; i++) {
    let row = rows[i];
    row = row.split(',');
    rows[i] = row;
  }
  return rows;

}
  /**
   * CSVの一行を引数に指定すると、
   * ""で囲まれた内部のコンマを分割せずに、
   * コンマで分割して配列の戻り値を返す関数
   * @param {string} line 文字列
   * @return {Array<string>} 配列
   */
   function csvSplit(line) {
    var c = "";
    var s = new String();
    var data = new Array();
    var singleQuoteFlg = false;

    for (var i = 0; i < line.length; i++) {
      c = line.charAt(i);
      if (c == "," && !singleQuoteFlg) {
        data.push(s.toString());
        s = "";
      } else if (c == "," && singleQuoteFlg) {
        s = s + c;
      } else if (c == '"') {
        singleQuoteFlg = !singleQuoteFlg;
      } else {
        s = s + c;
      }
    }
    if (s.length > 0) {
      data.push(s.toString());
    }
    return data;
  }

  //  gcの詳細ページを開く
  function showDetialContact(contact) {
    if (contact.conversation_id != undefined) {
      let conversation_id = contact.conversation_id;
      let url = "https://apps.mypurecloud.jp/directory/#/engage/admin/interactions/" + conversation_id+ "/details";
      window.open(url, '_blank')
    }
  }

function  directionText(direction) {
    return direction == 'outbound' ? 'アウトバウンド' : 'インバウンド'; 
}

function truncate(text, max = 20) {
  if (text) {
    if (text.length > max) {
      text = text.substring(0, max - 3) + '...';
    }
  }
  return text;

}

//
//  'tel:+'部分を削除する
//  
// 
function parseDnisAni(text) {
  if (text !== null) {
    let prefix = 'tel:';
    if (text.indexOf(prefix) === 0) {
      text = text.substring(prefix.length);
  
      if (text.indexOf('+') === 0) {
        text = text.substring(1);
      }
    }
    text = truncate(text, 15);
  
  }
  return text;

}


export default {
    doResponse,
    doResponseFire,
    toShiftJis,
    confirmDelete,
    confirmOK,
    toastError,
    makeArrayFromCSVText,
    csvSplit,
    readHeader,
    baseName,
    confirmYesNo,
    showDetialContact,
    directionText,
    truncate,
    parseDnisAni,
 
}