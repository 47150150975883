const  MSEC_DAY = 24*60*60*1000;
function toDate(dt) 
{

    if (typeof dt == 'string') {
        dt = new Date(dt);
    } else if (dt == null) {
        dt = new Date();
    }
    return dt;
}
function goNext(dtStart, dtEnd) {
    if (isMonthSpan(dtStart, dtEnd)) {
        //  翌月にする
        dtStart = dtEnd;
        dtEnd = new Date(dtEnd.getFullYear(), dtEnd.getMonth() + 1, 1);
    } else {
        let endTime = dtEnd.getTime();
        let startTime = dtStart.getTime();
        let span = endTime - startTime;
        dtStart = dtEnd;
        dtEnd = new Date(endTime + span);
        console.log('goNext', dtStart, dtEnd, span);
    }
    return [dtStart, dtEnd];

}
function goPrev(dtStart, dtEnd) {
    if (isMonthSpan(dtStart, dtEnd)) {
        //  先月にする
        dtEnd = dtStart;
        dtStart = new Date(dtStart.getFullYear(), dtStart.getMonth() -1, 1);
    } else {
        let endTime = dtEnd.getTime();
        let startTime = dtStart.getTime();
        let span = endTime - startTime;
        dtEnd = dtStart;
        dtStart = new Date(startTime - span);
        console.log('goPrev', dtStart, dtEnd, span);
    }
    return [dtStart, dtEnd];

}

//  月単位か調べる
function isMonthSpan(dtStart, dtEnd)
{
    if (dtStart.getDate() == 1 && dtEnd.getDate() == 1) {
        if (dtStart.getHours() == 0 && dtStart.getMinutes() == 0) {
            if (dtEnd.getHours() == 0 && dtEnd.getMinutes() == 0) {
                return true;
            }
        }
    }
    return false;

}
//  タイトル用に日付範囲テキスト
function getDateTimeIntervalTextForTitle(dtStart, dtEnd)
{
    let title = null;
    let force_time = true;
    let span = dtEnd.getTime() - dtStart.getTime();
    if (span == MSEC_DAY) {
        //  丁度一日
        title = getDateTimeTextForTitle(dtStart);
    } else if (span%MSEC_DAY == 0) {
        //  範囲が１日単位

        let hour = dtStart.getHours();
        let min = dtStart.getMinutes();
        if (hour == 0 && min == 0) {
            //  日付単位
            force_time = false;

            if (dtStart.getDate() == 1 && dtEnd.getDate() == 1) {
                //  月単位
                title = getYearMonthText(dtStart);
            }
        }
    }
    if (title == null) {
        title = getDateTimeTextForTitle(dtStart, force_time) + ' - ' + getDateTimeTextForTitle(dtEnd, force_time)
    }
    return title;


}
function getYearMonthText(dt)
{
    return dt.getFullYear() + '年' + (dt.getMonth() + 1) + '月' ; 
}

//  タイトル用に
//  yyyy年m月d日  <-- 00:00のとき
//  yyyy年m月d日 HH:MM  <-- 00:00以外のとき
function getDateTimeTextForTitle(dt, force_time = false)
{
    let title = dt.getFullYear() + '年' + (dt.getMonth() + 1) + '月' + dt.getDate() + '日'; 
    let hour = dt.getHours();
    let min = dt.getMinutes();
    if (force_time || hour || min) {
        title = title + ' ' + pad00(hour) + ':' + pad00(min);
    }
    return title;
}
///  yyyy-mm-dd　形式の日付テキストを取得
function getDateText(dt, delimit = '-')
{
    dt = toDate(dt);
    return '' + dt.getFullYear() + delimit + pad00(dt.getMonth() + 1) + delimit + pad00(dt.getDate()); 
}
///  HH:MM:SS　形式の時刻テキストを取得
function getTimeText(dt, withSec = true)
{
    dt = toDate(dt);
    let delimit = ':';
    return pad00(dt.getHours()) + delimit + pad00(dt.getMinutes()) + (withSec ? (delimit + pad00(dt.getSeconds())) : ''); 
}
function getDateTimeText(dt, delimit = '-', withSec = true)
{
    return getDateText(dt, delimit) + ' ' + getTimeText(dt, withSec);
}

//  yyyy-mm-dd　形式の日付テキストをDate型に変換
function parseDate(dateString) {
    if (dateString == null) 
    {
        return null;
    }
    return new Date(dateString);
}

//  dateの日付部分とtimeの日時部分をマージしてDateを取得
//  入力のdateとtimeはDateかstringのどちらか
function mergeDateAndTime(date, time)
{
    if (typeof date != 'string') {
        //  Date型と判定して yyyy-mm-ddに変換
        date = getDateText(date);
    }
    if (typeof time != 'string') {
        //  Date型と判定して yyyy-mm-ddに変換
        time = getTimeText(time);
    }
    return new Date(date + ' ' + time);

}
//  本日の00:00のdate
function getToday()
{
    let dt = new Date();
    dt.setHours(0);
    dt.setMinutes(0);
    dt.setSeconds(0);
    return dt;
}
//  翌日の00:00のdate
function getNextday()
{
    return getDayAdd(getToday(), 1);
}

//  指定日時に日付を加える
function getDayAdd(dt, days)
{
    dt = new Date(dt.getTime() + days*MSEC_DAY);
    return dt;
}
function pad00(value) {
    return ( '00' + value ).slice( -2 );
}
export default {
    getDateText,
    getTimeText,
    parseDate,
    mergeDateAndTime,
    getDateTimeText,
    getDateTimeTextForTitle,
    getDateTimeIntervalTextForTitle,
    getToday,
    getNextday,
    getDayAdd,
    goNext,
    goPrev,


}