<template>
  <v-main>
    <v-toolbar dense class="grey lighten-4 ma-0 pa-0" height="30" elevation="1">
        <v-layout wrap justify-end class="mr-n3">
          <v-layout justify-center style="margin-left:80px">
            <v-btn icon small color="blue darken-4" @click="onGoPrev()"><v-icon small>mdi-chevron-left</v-icon></v-btn>
            <v-btn text small color="blue darken-4" @click="onOpenStartedAtDialog()">{{started_at_title}}</v-btn>
            <v-btn icon small color="blue darken-4" @click="onGoNext()"><v-icon small>mdi-chevron-right</v-icon></v-btn>
           </v-layout>
           <!-- ツールバー右側のボタン-->
           <v-btn icon small color="grey darken-4" :disabled="!isAnyRowChecked" @click="deleteContacts">
              <v-icon small>mdi-trash-can-outline</v-icon>
            </v-btn>
           <v-btn icon small color="grey darken-4" @click="is_show_filter=!is_show_filter">
              <v-icon small>mdi-filter-settings</v-icon>
            </v-btn>
           <v-btn icon small color="grey darken-4" @click="getRows()">
              <v-icon small>mdi-reload</v-icon>
            </v-btn>
        </v-layout>
    </v-toolbar>


    <v-layout wrap  justify-end>
      <!-- メイン -->
      <v-card flat tile outlined class="main_content flex" :loading="is_loading">
        <v-card v-show="search_chips.length != 0" flat tile outlined style="padding-left:0.2em;padding-top:0.2em;">
          <div>
             <v-chip v-for="(chip, index) in search_chips" :key="index"
              style="margin-right:0.2em;margin-bottom: 0.2em;"
              close
              color="indigo darken-4"
              text-color="white"
              @click:close="onCloseChip(chip)"
            >
              {{chip.text}}
            </v-chip>
            <a class="text-subtitle-2 indigo--text text--darken-4" @click="onClearAll">すべてクリア</a>

          </div>
          

        </v-card>

        <!-- 通話一覧 -->
        <v-simple-table class="v-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  <input type="checkbox" v-model="is_all_row_checked" @click="onClickAllRow($event)"/> 
                </th>
                <th>方向</th>
                <th>ANI</th>
                <th>DNIS</th>
                <th>要約</th>
                <th>
                  <v-btn text @click="onChangeOrder">
                  日付
                  <v-icon>{{ searchParams.is_desc ? 'mdi-triangle-small-down' : 'mdi-triangle-small-up' }}</v-icon>
                  </v-btn>
                </th>
                <th>会話接続時間</th>
                <th>操作</th>

              </tr>
            </thead>
            <tbody>
                <tr v-for="row in rows.data" :key="row.id" @click.prevent.stop="onClickShowDetail(row)">

                <td>
                  <input type="checkbox" v-bind:checked="isRowChecked(row.id)" @click.stop="onClickRow($event, row.id)" />
                </td>

                <td>{{directionText(row.initial_direction)}}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{parseDniAni(row.ani_normalized)}}</span>
                    </template>
                    <span>{{row.ani_normalized}}</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{parseDniAni(row.dnis_normalized)}}</span>
                    </template>
                    <span>{{row.dnis_normalized}}</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{getSummary(row.summary)}}</span>
                    </template>
                    <span>{{row.summary}}</span>
                  </v-tooltip>
                </td>
                <td>{{row.started_at}}</td>
                <!-- td>{{timeSpan(row.started_at, row.ended_at)}}</td -->
                <td>{{Math.round(row.duration_ms / 1000)}}秒</td>


                <td>
                  <button type="button" class="btn btn-tool btn-sm" @click="showDetialContact(row)">
                    <img src="/images/logo_genesys.png" width="20px" />
                  </button>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
         <v-pagination
                v-model="selected_page"
                circle
                class="my-4"
                :length="pager_length"
                @next="onPagination()"
                @previous="onPagination()"
                @input="onPagination()"
              ></v-pagination>

      </v-card>
      <!--  フィルター -->
      <v-card flat tile outlined class="right_side_bar" v-show="is_show_filter">
        <v-toolbar dense class="grey darken-2" height="30" elevation="1">
          <v-toolbar-title  class="text-subtitle-2 white--text">フィルター</v-toolbar-title>
    
          <v-spacer></v-spacer>
    
          <v-btn small icon color="white"  @click="is_show_filter=false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
    
        </v-toolbar>
  
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-select
                :value="direction_value"
                item-text="label"
                item-value="value"
                :items="directions"
                label="方向"
                dense
                clearable
                 @change="onChangeDirection"
                ></v-select>

            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <!-- v-text-field label="ANIによるフィルター" v-model="searchParams.ani_normalized" clearable @change="onChangeAni"></v-text-field -->
              <v-text-field label="ANIによるフィルター" clearable @change="onChangeAni"></v-text-field>

            <span  style="margin-top:-1em;">
              <v-chip v-for="(chip, index) in ani_chips" :key="index"
                style="margin-right:0.2em;margin-bottom: 0.2em;"
                close
                color="indigo darken-4"
                text-color="white"
                @click:close="onCloseChip(chip)"
              >
                {{chip.text}}
              </v-chip>
            </span>


            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <!-- v-text-field label="DNISによるフィルター" v-model="searchParams.dnis_normalized" clearable @change="onChangeDnis"></v-text-field -->
              <v-text-field label="DNISによるフィルター" clearable @change="onChangeDnis"></v-text-field>
              <span  style="margin-top:-1em;">
                <v-chip v-for="(chip, index) in dnis_chips" :key="index"
                  style="margin-right:0.2em;margin-bottom: 0.2em;"
                  close
                  color="indigo darken-4"
                  text-color="white"
                  @click:close="onCloseChip(chip)"
                >
                  {{chip.text}}
                </v-chip>
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <!-- master-select2 label="キューによるフィルター"  v-model="searchParams.queue" :select_api="'/api/gc/queue'" @change="onChangeQueue" / -->
              <master-select2 label="キューによるフィルター"   :select_api="'/api/gc/queue'" @change="onChangeQueue" />

            <span  style="margin-top:-1em;">
              <v-chip v-for="(chip, index) in queue_chips" :key="index"
                style="margin-right:0.2em;margin-bottom: 0.2em;"
                close
                color="indigo darken-4"
                text-color="white"
                @click:close="onCloseChip(chip)"
              >
                {{chip.text}}
              </v-chip>
            </span>


            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <!-- master-select2 label="ユーザーによるフィルター"  v-model="searchParams.user" :select_api="'/api/gc/user'" @change="onChangeUser" / -->
              <master-select2 label="ユーザーによるフィルター"  :select_api="'/api/gc/user'" @change="onChangeUser" />

            <span  style="margin-top:-1em;">
              <v-chip v-for="(chip, index) in user_chips" :key="index"
                style="margin-right:0.2em;margin-bottom: 0.2em;"
                close
                color="indigo darken-4"
                text-color="white"
                @click:close="onCloseChip(chip)"
              >
                {{chip.text}}
              </v-chip>
            </span>


            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <!-- master-select2 label="ラップアップによるフィルター"  v-model="searchParams.wrapupcode" :select_api="'/api/gc/wrapupcode'" @change="onChangeWrapupcode" / -->
              <master-select2 label="ラップアップによるフィルター"  :select_api="'/api/gc/wrapupcode'" @change="onChangeWrapupcode" />

            <span  style="margin-top:-1em;">
              <v-chip v-for="(chip, index) in wrapupcode_chips" :key="index"
                style="margin-right:0.2em;margin-bottom: 0.2em;"
                close
                color="indigo darken-4"
                text-color="white"
                @click:close="onCloseChip(chip)"
              >
                {{chip.text}}
              </v-chip>
            </span>


            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <!-- v-text-field label="通話テキストによるフィルター" v-model="searchParams.text" clearable @change="onChangeText"></v-text-field-->
              <v-text-field label="通話テキストによるフィルター" clearable @change="onChangeText"></v-text-field>
              <span  style="margin-top:-1em;">
                <v-chip v-for="(chip, index) in text_chips" :key="index"
                  style="margin-right:0.2em;margin-bottom: 0.2em;"
                  close
                  color="indigo darken-4"
                  text-color="white"
                  @click:close="onCloseChip(chip)"
                >
                  {{chip.text}}
                </v-chip>
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <!-- v-text-field label="メモによるフィルター" v-model="searchParams.memo" clearable @change="onChangeMemo"></v-text-field -->
              <v-text-field label="メモによるフィルター"  clearable @change="onChangeMemo"></v-text-field>
              <span  style="margin-top:-1em;">
                <v-chip v-for="(chip, index) in memo_chips" :key="index"
                  style="margin-right:0.2em;margin-bottom: 0.2em;"
                  close
                  color="indigo darken-4"
                  text-color="white"
                  @click:close="onCloseChip(chip)"
                >
                  {{chip.text}}
                </v-chip>
              </span>
            </v-list-item-content>
          </v-list-item>

        </v-list>



        </v-card>
            
    </v-layout>

    <!-- 日付選択ダイアログ-->
    <dateinterval-dialog ref="dateinterval_dialog" :interval_from="started_at_from" @update:interval_from="started_at_from = $event"
                          :interval_to="started_at_to" @update:interval_to="started_at_to = $event" @done="onCloseStartedAtDialog"></dateinterval-dialog>


  </v-main>
</template>


<script>
import axios from 'axios';
import common from '../../common';
import my_date_lib from '../../my_date';
import DateintervalDialog from '../libs/DateintervalDialog.vue';
import MasterSelect2 from '../libs/MasterSelect2.vue';

const THIS_API = '/api/contact';
    export default {
        components: { DateintervalDialog, MasterSelect2 },
        computed: {
          ani_chips: function() {
            let chips = [];
              if (this.filter_condition && this.filter_condition.filter_condition_ani) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_ani, 'filter_condition_ani', 'gc_ani', null);
              }
            return chips;
          },
          dnis_chips: function() {
            let chips = [];
              if (this.filter_condition && this.filter_condition.filter_condition_dnis) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_dnis, 'filter_condition_dnis', 'gc_dnis', null);
              }
            return chips;
          },
          wrapupcode_chips: function() {
            let chips = [];
              if (this.filter_condition && this.filter_condition.filter_condition_wrapupcodes) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_wrapupcodes, 'filter_condition_wrapupcodes', 'name', null);
              }
            return chips;
          },
          user_chips: function() {
            let chips = [];
              if (this.filter_condition && this.filter_condition.filter_condition_users) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_users, 'filter_condition_users', 'name', null);
              }
            return chips;
          },
          queue_chips: function() {
            let chips = [];
              if (this.filter_condition && this.filter_condition.filter_condition_queues) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_queues, 'filter_condition_queues', 'name', null);
              }
            return chips;
          },
          text_chips: function() {
            let chips = [];
              if (this.filter_condition && this.filter_condition.filter_condition_texts) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_texts, 'filter_condition_texts', 'text', null);
              }
            return chips;
          },
          memo_chips: function() {
            let chips = [];
              if (this.filter_condition && this.filter_condition.filter_condition_memos) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_memos, 'filter_condition_memos', 'memo', null);
              }
            return chips;
          },
          search_chips: function() {
            let chips = [];

            if (this.filter_condition) {
              if (this.filter_condition.is_inbound && this.filter_condition.is_outbound) {
                //  アウトバウンドとインバウンドの両方設定されている場合は、フィルターがないのと同じ

              } else {
                if (this.filter_condition.is_outbound) {
                  let chip = {
                    name: 'is_outbound',
                    reset_value: false,
                    text: '方向:アウトバウンド',
                  };
                  chips.push(chip);
                }
                if (this.filter_condition.is_inbound) {
                  let chip = {
                    name: 'is_inbound',
                    reset_value: false,
                    text: '方向:インバウンド',
                  };
                  chips.push(chip);
                }

              }
              if (this.filter_condition.filter_condition_ani) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_ani, 'filter_condition_ani', 'gc_ani', 'ANI');
              }
              if (this.filter_condition.filter_condition_dnis) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_dnis, 'filter_condition_dnis', 'gc_dnis', 'DNIS');
              }
              if (this.filter_condition.filter_condition_queues) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_queues, 'filter_condition_queues', 'name', 'キュー');
              }
              if (this.filter_condition.filter_condition_wrapupcodes) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_wrapupcodes, 'filter_condition_wrapupcodes', 'name', 'ラップアップ');
              }
              if (this.filter_condition.filter_condition_users) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_users, 'filter_condition_users', 'name', 'ユーザー');
              }
              if (this.filter_condition.filter_condition_texts) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_texts, 'filter_condition_texts', 'text', 'テキスト');
              }
              if (this.filter_condition.filter_condition_memos) {
                this.makeChipsFromArray(chips, this.filter_condition.filter_condition_memos, 'filter_condition_memos', 'memo', 'メモ');
              }


            }
            return chips;
          },
          isAnyRowChecked: function () {
            return (this.is_all_row_checked || this.checked_rows.length > 0);
          },
          pager_length: function() {
            let length = 0;
            if (this.rows && this.rows.data) {
              length = this.rows.current_page;
              let count = this.rows.data.length;
              let limit = this.rows.per_page;
              if (count == limit) {
                //  すべて取得できたとき。次のページもあるかもしれないので、ページ数をアップ
                length = length + 1;
              }
            }
            return length;
          },
          started_at_title: function() {
            return this.updateStartedAtTitle();
          },
          direction_value: function() {
            if (!this.filter_condition) {
              return null;
            }
            return this.filter_condition.is_outbound ? 'outbound' : (this.filter_condition.is_inbound ? 'inbound' : null);
          }
        },

        data () {
            return {
              picker_start : null,
              picker : null,
              is_show_filter: false,
              directions: [
                { label: 'インバウンド' , value: 'inbound'    },
                { label: 'アウトバウンド' , value: 'outbound'   },
              ],
              is_loading: false,
//              is_show_datetime_picker: false,
              selected_page: 1,   //  現在選択されているページ（pager用）
              started_at_from: null,      //  日付 from　（ダイアログ用）
              started_at_to: null,    //  日付 to　（ダイアログ用）
              /*
              started_at_preset_items: [
                { title: '今日', type: 1, days: 1},
                { title: '昨日', type: 2, days: 1},
                { title: '今週', type: 3, days: 7},
                { title: '先週', type: 4, days: 7},
                { title: '過去7日間', type: 5, days: 7},
                { title: '今月', type: 6, days: 0},
                { title: '先月', type: 7, days: 0},
                { title: '過去30日間', type: 8, days: 30},

              ],
              */
//              started_at_title: null,


                editmode: false,
                searchParams: {
                  /*
                  initial_direction: '',
                  ani_normalized: '',
                  dnis_normalized: '',
                  text: '',
                  memo: '',
                  started_at: '',
                  user: null,
                  queue: null,
                  wrapupcode: null,
                  */
                  started_at_from: null,  //  日付 from
                  started_at_to: null,    //  日付 to
                  is_desc: 1,  //  1: 降順 / 0: 昇順
                  order_by: 'started_at',
                   
                },

                rows : {},

                users : {},
                form: new Form({
                    id : '',
                    type : '',
                    name: '',
                    email: '',
                    password: '',
                    email_verified_at: '',
                }),
                filter_condition: null,
                ////  チェック機能関係
                is_all_row_checked: false,
                checked_rows: [],   //  選択状態の行IDの配列（is_all_row_checkedがfalseのとき有効のとき有効）
                unchecked_rows: [],   //  非選択状態の行IDの配列（is_all_row_checkedがtrueのとき有効）
                ////

            }
        },
        methods: {
          onCloseChip(chip) {
            let name = chip.name;
           // this.searchParams[name] = '';

           if (Array.isArray(this.filter_condition[name])) {
            //  配列のとき
            //  chip.reset_valueに削除する要素のidが設定されているので、それ以外の配列にする
            let new_array = this.filter_condition[name].filter(elm => elm.id != chip.reset_value);
            this.filter_condition[name] = new_array;

           } else {
            this.filter_condition[name] = chip.reset_value;

           }
           this.updateFilterCondition();
          },
          onClearAll() {
            this.filter_condition.is_outbound = false;
            this.filter_condition.is_inbound = false;
            this.filter_condition['filter_condition_ani'] = [];
            this.filter_condition['filter_condition_dnis'] = [];
            this.filter_condition['filter_condition_users'] = [];
            this.filter_condition['filter_condition_queues'] = [];
            this.filter_condition['filter_condition_wrapupcodes'] = [];
            this.filter_condition['filter_condition_texts'] = [];
            this.filter_condition['filter_condition_memos'] = [];

           this.updateFilterCondition();
          },
          makeChipsFromArray(chips, array, name, value_field, value_prefix) {
            if (value_prefix != null) {
              value_prefix = value_prefix + ':';
            } else {
              value_prefix = '';
            }
            array.forEach(elm => {
              let chip = {
                name: name,
                reset_value: elm.id,
                text: value_prefix + elm[value_field],
              };
              chips.push(chip);
            });

          },
          onChangeSearch() {
            this.getRows();

          },
          //  詳細ページへ
          onClickShowDetail(row) {

            let param = { name: 'contact_detail', params: { id: row.id } };
            this.$router.push(param);

          },
          //  ページネーションクリック
          onPagination() {
            //  チェックははずさないように
            this.getRows(this.selected_page, false);
          },
          onChangeOrder() {
            this.searchParams.is_desc = this.searchParams.is_desc ? 0 : 1;
            this.getRows();

          },
          onOpenStartedAtDialog() 
          {
              this.started_at_from = my_date_lib.parseDate(this.searchParams.started_at_from);
              this.started_at_to = my_date_lib.parseDate(this.searchParams.started_at_to);
              //  ダイアログを開く
//              this.is_show_datetime_picker = true;
              this.$refs.dateinterval_dialog.open();

          },
          onCloseStartedAtDialog() {
              //  取得した範囲(Date型）をsearchParams(string型)に設定
              this.setSeachStartedAtFromTo(this.started_at_from, this.started_at_to);

              this.getRows();

          },
          /*
          onCloseStartedAtDialog(is_done) {
            if (is_done) {
              //  更新

              //  取得した範囲(Date型）をsearchParams(string型)に設定
              this.setSeachStartedAtFromTo(this.started_at_from, this.started_at_to);

              this.getRows();

            }
            //  ダイアログを閉じる
            this.is_show_datetime_picker = false

//            this.started_at_title = this.updateStartedAtTitle();
          },
          */
          //  取得した範囲(Date型）をsearchParams(string型)に設定
          setSeachStartedAtFromTo(from, to) {
              this.searchParams.started_at_from = my_date_lib.getDateTimeText(from);
              this.searchParams.started_at_to = my_date_lib.getDateTimeText(to);
          },

          updateStartedAtTitle() {
            let started_at_from = my_date_lib.parseDate(this.searchParams.started_at_from);
            let started_at_to = my_date_lib.parseDate(this.searchParams.started_at_to);
            return my_date_lib.getDateTimeIntervalTextForTitle(started_at_from, started_at_to);
            
          },
          /*
          //  日付範囲のプリセット選択時
          onSelectPresetItem(item) {
            console.log('onSelectPresetItem', item);
            let days = item.days;
            let dt_to;
            let dt_from;
            let today = my_date_lib.getToday();
            switch (item.type) {
              case 1:  // 今日
              case 5:  // 過去７日
              case 8:  // 過去30日
                dt_to = my_date_lib.getNextday();
                break;
              case 2:  // 昨日
                dt_to = today;
                break;
              case 3:  // 今週
                today.setDate(today.getDate() - today.getDay() + 7);
                dt_to = today;
                break;
              case 4:  // 先週
                today.setDate(today.getDate() - today.getDay());
                dt_to = today;
                break;
              case 6:  // 今月
                dt_to = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                dt_from = new Date(today.getFullYear(), today.getMonth(), 1);
                break;
              case 7:  // 先月
                dt_to = new Date(today.getFullYear(), today.getMonth(), 1);
                dt_from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                break;

            }
            if (days) {
              dt_from = my_date_lib.getDayAdd(dt_to, -days);
            }
            this.started_at_from = dt_from;
            this.started_at_to = dt_to;
          },
          */
          onGoNext() {
            let from = my_date_lib.parseDate(this.searchParams.started_at_from);
            let to = my_date_lib.parseDate(this.searchParams.started_at_to);

            [from, to] = my_date_lib.goNext(from, to);
            //  取得した範囲(Date型）をsearchParams(string型)に設定
            this.setSeachStartedAtFromTo(from, to);

            //  再取得
            this.getRows();
          },
          onGoPrev() {
            let from = my_date_lib.parseDate(this.searchParams.started_at_from);
            let to = my_date_lib.parseDate(this.searchParams.started_at_to);

            [from, to] = my_date_lib.goPrev(from, to);
            //  取得した範囲(Date型）をsearchParams(string型)に設定
            this.setSeachStartedAtFromTo(from, to);

            //  再取得
            this.getRows();
          },
          getSetting() {
              axios.get(THIS_API + '_setting').then(({ data }) => {

                  this.filter_condition = data.data;
                //  console.log('filter_condition', this.filter_condition);
                  this.getRows();

              });

          },

            getRows(page = 1, is_reset_check = true) {
              this.is_loading = true;
              if (is_reset_check) {
                this.resetCheck();
              }
              let params = ['page=' + page];

              for(let key in this.searchParams) {
                let value = this.searchParams[key];
                if (value !== null) {
                  params.push(key + '=' + encodeURIComponent(value));

                }
              }
              let query = params.join('&');
           //   console.log('query', query);

//              this.$Progress.start();
              
              axios.get(THIS_API + '?' + query).then(({ data }) => {

                  this.rows = data.data;
                  this.is_loading = false;
                  this.selected_page = page;
              }).catch((data)=> {
                this.is_loading = false;
                Swal.fire("Failed!", data.message, "warning");

              });

//              this.$Progress.finish();
            },
            directionText(direction) {
              return common.directionText(direction);
            },
            timeSpan(start_text, end_text) {
                let start = new Date(start_text);
                let end = new Date(end_text);
                let diffTime = end.getTime() - start.getTime();
                let diffSec = Math.floor(diffTime / 1000 );
                let min = Math.floor(diffSec / 60);
                let sec = diffSec % 60;

                let result = '';
                if (min > 0) {
                    result = min + '分';
                }
                return result = result + sec + '秒';
            },
            resetSearchParams() {
                for(let key in this.searchParams) {
                    this.searchParams[key] = '';
                }
            },

            editModal(user){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(user);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
          
          //  選択された行を削除
          deleteContacts() {

            Swal.fire({
              title: '選択された行を削除します。本当に削除してよろしいですか？',
//              input: 'checkbox',
//              inputValue: true,
//              inputPlaceholder: 'GenesysCluod上の通録データも削除する',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#3085d6',
              confirmButtonText: '削除',
              cancelButtonText: 'キャンセル',
              showLoaderOnConfirm: true,
              preConfirm: (login) => {
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                this.doDeleteRows();
                /*
                if (result.value) {
                  Swal.fire({icon: 'success', text: "削除しました"});
                } else {
                  Swal.fire({icon: 'error', text: "削除しました"});
                } 
                */               
              }
            });            
          },
          //  選択された行を削除
          doDeleteRows() {
            let result = {params:null};
            this.getParams(result);
            let params = result.params;

            this.$Progress.start();
            axios.post(THIS_API + '/deletes', params)
              .then((response) => {
                this.doResponse(response);
              }).catch((data)=> {
                Swal.fire("Failed!", data.message, "warning");
              });

          },
          doResponse(response, emitIfSuccess) {
                if(response.data.success){
                  // success
                  Swal.fire(
                    '削除完了!',
                    '削除されました',
                    'success'
                    );

                  //  再読み込み
                  this.getRows(this.selected_page);
                  this.$Progress.finish();
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: response.data.message
                  });
                  this.$Progress.finish();
              }
          },
          getParams(result) {
              result.params = {
                  is_all_row_checked: this.is_all_row_checked,
                  checked_rows: this.checked_rows,
                  unchecked_rows: this.unchecked_rows,
                  search_params: this.searchParams,
              };
          },


          //  GCの詳細ページを開く
          showDetialContact(contact) {
            common.showDetialContact(contact);
          },


          ////  チェック機能関係
          resetCheck() {
            this.checked_rows = [];
            this.unchecked_rows = [];
            this.is_all_row_checked = false;
          },
          onClickAllRow(event) {
            this.checked_rows = [];
            this.unchecked_rows = [];

          },
          onClickRow(event, key) {
//            console.log(event.target.checked);
            let isChecked = event.target.checked;
            let rows;
            if (this.is_all_row_checked) {
              //  すべてチェック状態のとき
              if (isChecked) {
                //  チェックされたら値を削除
                let index = this.unchecked_rows.indexOf(key);
                if (index != -1) {
                  this.unchecked_rows.splice(index, 1);
                }
              } else {
                //   チェック解除で値を追加
                this.unchecked_rows.push(key);
              }
            } else {
              //  すべてチェックされていない状態のとき
              if (isChecked) {
                //   チェックされたら値を追加
                this.checked_rows.push(key);
              } else {
                //  チェック解除で値を削除
                let index = this.checked_rows.indexOf(key);
                if (index != -1) {
                  this.checked_rows.splice(index, 1);
                }
              }
            }
          },
          isRowChecked(key) {
            if (this.is_all_row_checked) {
              //  すべてチェック状態のとき
              let index = this.unchecked_rows.indexOf(key);
              if (index != -1) {
                return false;
              } else {
                return true;
              }
            } else {
              //  すべてチェックされていない状態のとき

              let index = this.checked_rows.indexOf(key);
              if (index != -1) {
                return true;
              } else {
                return false;
              }

            }
          },
          /////

          getSummary(summary) {
            return common.truncate(summary, 20);
          },
          parseDniAni(text) {
            return common.parseDnisAni(text);
          },

          //  日付範囲の初期化（本日）
          initSeachStartedAt() {
            this.setupSearchStartedAt(-1);

          },

          setupSearchStartedAt(add_days)
          {
            //  toは翌日までにする
            let started_at_to = my_date_lib.getNextday();

            //  fromは翌日からの日数（マイナス値）
            let started_at_from = my_date_lib.getDayAdd(started_at_to, add_days);

            //  取得した範囲(Date型）をsearchParams(string型)に設定
            this.setSeachStartedAtFromTo(started_at_from, started_at_to);

          },

          onChangeUser(value) {
            if (value) {
              this.onChangeFilterCondition('filter_condition_users', value);
            }
          },
          onChangeQueue(value) {
            if (value) {
              this.onChangeFilterCondition('filter_condition_queues', value);
            }
          },
          onChangeWrapupcode(value) {
            if (value) {
              this.onChangeFilterCondition('filter_condition_wrapupcodes', value);
            }
          },
          onChangeDirection(value) {
            console.log('onchangedirection', value);
            this.filter_condition.is_outbound = false;
            this.filter_condition.is_inbound = false;
            if (value == 'outbound') {
              this.filter_condition.is_outbound = true;
            }
            if (value == 'inbound') {
              this.filter_condition.is_inbound = true;
            }
            this.updateFilterCondition();

          },
          onChangeAni(value) {
            if (value) {
              value = {
                id: this.getNextId('filter_condition_ani'),
                gc_ani: value,
              };
              this.onChangeFilterCondition('filter_condition_ani', value);
            }
          },
          onChangeDnis(value) {
            if (value) {
              value = {
                id: this.getNextId('filter_condition_dnis'),
                gc_dnis: value,
              };
              this.onChangeFilterCondition('filter_condition_dnis', value);
            }
          },
          onChangeText(value) {
            if (value) {
              value = {
                id: this.getNextId('filter_condition_texts'),
                text: value,
              };
              this.onChangeFilterCondition('filter_condition_texts', value);
            }
          },
          onChangeMemo(value) {
            if (value) {
              value = {
                id: this.getNextId('filter_condition_memos'),
                memo: value,
              };
              this.onChangeFilterCondition('filter_condition_memos', value);
            }
          },
          onChangeFilterCondition(array_field_name, value) {
            let array = [];
            if (this.filter_condition[array_field_name] !== undefined) {
              array = this.filter_condition[array_field_name];
            }
            let is_update = false;
            if (!array.find(elm => elm.id == value.id)) {
              //  見つからないときは追加
              array.push(value);
              is_update = true;
            }
            if (is_update) {
              this.filter_condition[array_field_name] = array;

              this.updateFilterCondition();
            }

          },
          onChangeFilterConditionValue(field_name, value) {
            if(this.filter_condition[field_name] != value) {
              this.filter_condition[field_name] = value;

              this.updateFilterCondition();

            }
          },
          getNextId(filter_condition_field_name) {
            if (this.filter_condition[filter_condition_field_name] === undefined) {
              this.filter_condition[filter_condition_field_name] = [];
            }
            let array = this.filter_condition[filter_condition_field_name];
            let max_id = 1;

            array.forEach((elm) => { max_id = Math.max(max_id, elm.id + 1)} );

            console.log('max_id',array, max_id);

            return max_id;
          },
          updateFilterCondition() {

            this.is_loading = true;
            axios.put(THIS_API + '_setting', { filter_condition: this.filter_condition } ).then((data) => {
                this.is_loading = false;

//                console.log('updateFilterCondition', THIS_API + '_setting', this.filter_condition);

                this.getRows();

            });


          },


        },
        mounted() {
  //          console.log('contacts Component mounted.')
        },
        created() {
//            console.log('contacts Component created.')

            //  日付範囲を本日に設定
            this.initSeachStartedAt();

            if (!this.rows.data) {
              
//              this.getRows();
              this.getSetting();

            }
        }
    }
</script>
