<template>
  <gc-setting :api="'/api/gc/division'" :name="'アクセス区分'"></gc-setting>
</template>
  

<script>
  import GcSetting from './GcSetting.vue';
  export default {
    components: { GcSetting },
  }

</script>
  