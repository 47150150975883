<style scoped lang="scss">
.keyword_col {
	padding-top:0.2em;
}
.large_keyword_col {
	@extend .keyword_col;
	width:200px;
}
.small_keyword_col {
	@extend .keyword_col;
	width:30px;
}
.keyword-card {
  font-size: 20px;
  font-family: "Courier New", Consolas, monospace, serif;
}
.keyword-text-block {
	padding: 0.2em;
}

// v-card-text スタイル上書き
.v-card__text {
	padding: 0.5em;

}

</style>
<template>
	<td v-if="row && row.contact_keywords && row.contact_keywords.length > 0" :class="{large_keyword_col: is_large_keyword_col, small_keyword_col: !is_large_keyword_col}" valign="top">
		<v-card class="keyword-card">
			<v-toolbar dense  elevation="1" color="lighten-5">
				<v-btn icon small color="grey darken-4" @click="is_large_keyword_col=!is_large_keyword_col">
					<v-icon large v-if="!is_large_keyword_col" >mdi-chevron-left</v-icon>
					<v-icon large v-if="is_large_keyword_col">mdi-chevron-right</v-icon>
				</v-btn>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon small
							v-bind="attrs"
							v-on="on"
							@click="toggleKeywords()"
						>
							<v-icon
						>mdi-tag</v-icon>

						</v-btn>
					</template>
					<span>キーワード選択・解除</span>
				</v-tooltip>
  				
				<v-toolbar-title v-if="is_large_keyword_col">キーワード</v-toolbar-title>
			</v-toolbar>
			<v-card-text>
				<!-- v-icon medium>mdi-tag</v-icon -->
				<div v-for="keyword in row.contact_keywords" class="keyword-text-block" :key="keyword.id">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-chip v-bind="attrs" v-on="on" @click="toggleKeyword(keyword)" :color="keywordColor(keyword)">{{chipTextFromKeyword(keyword)}}</v-chip>
						</template>
						<span>{{keyword.text}}</span>
					</v-tooltip>

				</div>
				
			</v-card-text>

		</v-card>

	</td>
	
</template>


<script>
import moment from 'moment';
import common from '../../common';

const THIS_API = '/api/contact';
	export default {
		
		name: 'keyword',
		props: ['row'],

		computed: {
		},
		data () {
			return {
				is_large_keyword_col: false,
			}
		},
		methods: {
			chipTextFromKeyword(keyword) {
				if (this.is_large_keyword_col) {
					return keyword.text;
				} else {
					//  縮小表示のときは最初の一文字
					return keyword.text.charAt(0);
				}

			},
			toggleKeyword(keyword) {
//				keyword.is_selected = keyword.is_selected ? false : true;
				let is_selected = keyword.is_selected ? false : true;
				this.$set(keyword, "is_selected", is_selected);
			},
			//  キーワードの全選択、選択解除
			//  一つでも選択されているキーワードがあればすべてクリア
			toggleKeywords() {
				let found = this.row.contact_keywords.find(keyword => keyword.is_selected);
				let is_selected;
				if (found) {
					//  すべてクリア
					is_selected = false;
				} else {
					//  全選択
					is_selected = true;
				}
				this.row.contact_keywords.forEach(keyword => {
					this.$set(keyword, "is_selected", is_selected);
				});

			},
			keywordColor(keyword) {
				if (keyword.is_selected) {
					return 'primary';

				} else {
					return 'default';
				}
			}

		},
		mounted() {
		},
		created() {

		},
	}
</script>
