<template>
<div class="card">

    <div class="card-body p-0">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th>曜日</th>
                    <th>開始時刻</th>
                    <th>終了時刻</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <time-set v-for="(timeset, index) in value"  v-model="value[index]" :key="index" @add="onAdd(index)" @remove="onRemove(index)" />
            </tbody>
        </table>
    </div>

</div>

</template>

<script>
import TimeSet from './TimeSet.vue';
    export default {
        components: { TimeSet },
        name: 'time-sets',
        props: {
            value: {
                type: Array,
            },
        },
        computed: {
            inputedValue: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    this.$emit("input", newValue);
                },
            },
        },

        data () {
            return {
            }
        },
        methods: {
            onAdd(index) {
                this.$emit('add', index);
            },
            onRemove(index) {
                this.$emit('remove', index);
            },

        },
        mounted() {
        },
        created() {
        }
    }
</script>
