<style scoped lang="scss">
    .form {
        .v-card__title {
            margin: 0;
            padding: 0;
        }

    }
</style>
<template>
  <v-main>
        <v-card  flat outlined tile class="ma-3" :loading="is_loading">
            <v-toolbar dense  elevation="1">
                <v-toolbar-title  class="text-subtitle-2">テキスト化対象の条件一覧</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" v-if="can(settings.Permissions.EDIT_FILTER_SETTING)"   icon  @click="newModal">
                            <v-icon color="primary">mdi-plus-circle</v-icon>
                        </v-btn>

                    </template>
                    <span>条件追加</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon small color="grey darken-4" @click="getRows()">
                          <v-icon small>mdi-reload</v-icon>
                        </v-btn>
                      </template>
                      <span>表示更新</span>
                  </v-tooltip>

            </v-toolbar>
            <v-simple-table class="v-table">
              <template v-slot:default>
                <thead>
                  <tr>
                      <th>ID</th>
                      <th>名前</th>
                      <th>登録日時</th>
                      <th>ステータス</th>
                      <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                     <tr v-for="row in rows.data" :key="row.id">

                      <td>{{row.id}}</td>
                      <td class="text-capitalize">{{row.name}}</td>
                      <td>{{row.created_at | moment_datetime}}</td>
                      <td>


                            <v-switch class="ma-0 pa-0"
                              :disabled="!can(settings.Permissions.EDIT_FILTER_SETTING)"
                                v-model="row.is_valid"
                                @change="onValidWitchChanged(row)"
                                :label="row.is_valid ? '有効' : '無効'"
                                hide-details
                            ></v-switch>
                      </td>
                      <td>

                        <a href="#" @click="editModal(row)">
                            <v-icon color="blue">mdi-application-edit-outline</v-icon>
                        </a>
                      </td>
                    </tr>
                </tbody>

              </template>
            </v-simple-table>


            <v-card-actions>
                <v-spacer></v-spacer>
                <v-pagination
                    v-model="selected_page"
                    circle
                    class="my-4"
                    :length="pager_length"
                    @next="onPagination()"
                    @previous="onPagination()"
                    @input="onPagination()"
                ></v-pagination>
                <v-spacer></v-spacer>

            </v-card-actions>
        </v-card>

  <!-- ダイアログ-->
  <v-dialog
    v-model="is_show_dialog"
    width="800"
    persistent
  >
    <v-card>
      <v-toolbar dense  elevation="1">
          <v-toolbar-title  class="text-subtitle-2">{{ editmode ? '更新' : '新規条件' }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn  icon  @click="is_show_dialog = false;">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
      </v-toolbar>
      <v-container>
        <v-form ref="form">
        <v-row>
            <v-col class="ma-0 pa-0">
                <v-card flat tile outlined height="100%" class="pl-6 pr-3 py-3">

                <v-text-field label="名前" v-model="form.name" :rules="[rules.required]" clearable></v-text-field>

                <v-checkbox class="ma-0 pa-0"
                    v-model="form.is_transcribe"
                    label="右の条件で音声テキスト化する"></v-checkbox>

                <v-checkbox class="ma-0 pa-0" v-show="form.is_transcribe"
                    v-model="form.is_summarize"
                    label="テキストを要約する"></v-checkbox>
                <v-checkbox class="ma-0 pa-0" v-show="form.is_transcribe"
                    v-model="form.is_keyword_extraction"
                    label="テキストからキーワードを抽出する"></v-checkbox>

                <v-checkbox class="ma-0 pa-0"
                    v-model="form.is_transfer"
                    label="右の条件で音声ファイルをs3に転送する"></v-checkbox>
                </v-card>

            </v-col>
            <v-col class="ma-0 pa-0">
                <v-card flat tile outlined height="100%" class="pa-3">
                    <v-card flat tile class="form">
                        <v-card-title>会話の方向</v-card-title>
                        <v-card-text>
                            <v-card  class="d-flex align-start" flat tile> 
                                <v-checkbox class="ma-0 pa-0"
                                    v-model="form.filter_condition.is_inbound"
                                    label="インバウンド"></v-checkbox>
                                <v-checkbox class="ma-0 pa-0"
                                    v-model="form.filter_condition.is_outbound"
                                    label="アウトバウンド"></v-checkbox>

                            </v-card>

                        </v-card-text>
            
                    </v-card>

                    <v-card flat tile class="form">
                        <v-checkbox class="ma-0 pa-0" v-model="form.filter_condition.is_timeset"  @change="onChangeTimeset">
                            <template v-slot:label>
                                <v-card-title>
                                    通話時刻
                                </v-card-title>
                            </template>
                        </v-checkbox>
                        <v-card-text class="mt-n5 pt-0">
                            <time-sets v-show="form.filter_condition.is_timeset" v-model="form.filter_condition.filter_condition_time_sets" @add="onAddTimeset" @remove="onRemoveTimeset" />
                        </v-card-text>
                    </v-card>

                    <v-card flat tile class="form">
                        <v-card-title>接続時間</v-card-title>
                        <v-card-text>
                            <vue-timepicker v-model="form.filter_condition.duration_time_from">
                            </vue-timepicker>
                            〜
                            <vue-timepicker v-model="form.filter_condition.duration_time_to">
                            </vue-timepicker>

                        </v-card-text>
                    </v-card>

                    <v-card flat tile class="form">
                        <v-card-title>特定のユーザー</v-card-title>
                        <v-card-text>
                            <master-select v-model="form.filter_condition.filter_condition_users" :select_api="'/api/gc/user'" />
                        </v-card-text>
                    </v-card>
                    <v-card flat tile class="form">
                        <v-card-title>特定のキュー</v-card-title>
                        <v-card-text>
                            <master-select v-model="form.filter_condition.filter_condition_queues" :select_api="'/api/gc/queue'" />
                        </v-card-text>
                    </v-card>
                    <v-card flat tile class="form">
                        <v-card-title>特定のラップアップコード</v-card-title>
                        <v-card-text>
                            <master-select  v-model="form.filter_condition.filter_condition_wrapupcodes" :select_api="'/api/gc/wrapupcode'" />
                        </v-card-text>
                    </v-card>

                </v-card>
                
            </v-col>
        </v-row>
        </v-form>
        </v-container>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="is_show_dialog=false;">キャンセル</v-btn>
          <v-btn v-if="can(settings.Permissions.EDIT_FILTER_SETTING)"   text color="primary" @click="onUpdate()">
            {{ editmode ? '更新' : '登録'}}
          </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

  </v-main>

</template>

<script>
import axios from 'axios';
import MasterSelect from '../libs/MasterSelect.vue'
import TimeSets from '../libs/TimeSets.vue'
import common from '../../common';
import moment from 'moment';
import settings from '../../consts/settings';

const THIS_API = '/api/settings/filter_setting';
    export default {
        filters: {
            moment_datetime: function (date) {
                return moment(date).format('YYYY-MM-DD HH:mm:ss');
            }
        },        
        components: {
            MasterSelect,
            TimeSets,
        },
        computed: {
          settings: function () {
            return settings;
          },
          pager_length: function() {
            let length = 0;
            if (this.rows && this.rows.data) {
              length = this.rows.current_page;
              let count = this.rows.data.length;
              let limit = this.rows.per_page;
              if (count == limit) {
                //  すべて取得できたとき。次のページもあるかもしれないので、ページ数をアップ
                length = length + 1;
              }
            }
            return length;
          },
        },

        data () {
            return {
                selected_page: 1,       //  現在選択されているページ（pager用）
                is_show_dialog: false,
                rules: {
                  required: value => !!value || '必須入力',
                  min: v => v.length >= 6 || '6文字以上',
                },

                editmode: false,
                rows : {},
                form: new Form({
                    id : '',
                    name: '',
                    is_valid: false,                 //  有効・無効識別\n 0:無効。1:有効");
                    is_transcribe: false,            //  音声テキスト化設定\n 0:しない。1:する");
                    is_summarize: true,             //  要約設定\n 0:要約しない。1:要約する。is_transcribeが1のときのみ有効");
                    is_keyword_extraction: true,    //  キーワード抽出\n 0:しない。1:する。is_transcribeが1のときのみ有効");
                    is_transfer: false,              //  音声ファイル転送設定\n 0:しない。1:する");

                    filter_condition: {

            //        is_direction: false,     //  会話の方向\n会話の方向を抽出条件とするときtrue
                        is_inbound: false,       //  インバウンド
                        is_outbound: false,      //  インバウンド
                        // is_user: false,          //  特定のユーザー\n特定のユーザーを抽出条件とするときtrue。対象のユーザーはfilter_condition_usersテーブル
                        filter_condition_users: [], //  対象のユーザー一覧
                        is_timeset: false,       //  時間セット\n時間帯を抽出条件とするときtrue。詳細はfilter_condition_time_setsテーブル
                        filter_condition_time_sets: [], //  対象の時間セット一覧
                        //  is_queue: false,         //  特定のキュー\n特定のキューを抽出条件とするときtrue。対象のキューはfilter_condition_queuesテーブル
                        filter_condition_queues: [], //  対象のキュー一覧
                        // is_wrapupcodes: false,   //  特定のラップアップコード\n特定のラップアップコードを抽出条件とするときtrue。対象のラップアップコードはfilter_condition_wrapupcodesテーブル
                        filter_condition_wrapupcodes: [], //  対象のラップアップコード一覧
                        //  is_duration_time: false, //  接続時間\n接続時間を抽出条件とするときtrue
                        duration_time_from: '',    //  接続時間範囲開始秒\n値以上
                        duration_time_to: '',      //  接続時間範囲終了秒\n値以下
                    }

                }),
                searchParams: {},
                is_loading: false,
//                selected: '',
//                options: [],


            }
        },
        methods: {
          //  ページネーションクリック
          onPagination() {
            //  チェックははずさないように
            this.getRows(this.selected_page);
          },
          onUpdate() {
            if (this.$refs.form.validate()) {

              if (this.editmode) {
                this.updateRow();

              } else {
                this.createRow();
              }
            }

          },



            getRows(page = 1) {
                let params = ['page=' + page];

                for(let key in this.searchParams) {
                  params.push(key + '=' + encodeURIComponent(this.searchParams[key]));
                }
                let query = params.join('&');

                this.is_loading = true;
                
                axios.get(THIS_API + '?' + query).then(({ data }) => {
                    this.rows = data.data;
                    this.is_loading = false;

//                    console.log('get rows', this.rows, data);
                }).catch((data)=> {
                    Swal.fire("Failed!", data.message, "warning");
                    this.is_loading = false;

                });

            },
            updateRow(){
                this.$Progress.start();
                console.log('updaterow', this.form);
                this.form.put(THIS_API + '/'+this.form.id)
                .then((response) => {
                    // success
    //                $('#addNew').modal('hide');
                    this.is_show_dialog = false;
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.getRows();
                }).catch((data)=> {
                    this.$Progress.finish();
                    Swal.fire("Failed!", data.message, "warning");
                });

            },
            editModal(row){
                this.editmode = true;
                this.form.reset();
//                $('#addNew').modal('show');
//                console.log('before fill', this.form);
                this.form.fill(row);
                console.log('fill', this.form, row);
                this.is_show_dialog = true;

                this.resetDurationTime();

            },
            resetDurationTime() {
                //  時刻の初期値がnullだとオブジェクトとして時刻が戻るので、文字列で戻るように
                if (!this.form.filter_condition.duration_time_from) {
                    //  時刻表示が更新されるように
                    Vue.set(this.form.filter_condition, 'duration_time_from', '');
                    console.log('reset 1');
                }
                if (!this.form.filter_condition.duration_time_to) {
                    //  時刻表示が更新されるように
                    Vue.set(this.form.filter_condition, 'duration_time_to', '');
                    console.log('reset 2');
                }

            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                this.resetDurationTime();

//                console.log('reset fill', this.form);
//                $('#addNew').modal('show');
                this.is_show_dialog = true;
            },

            
            createRow(){
//                console.log('createRow');

                this.form.post(THIS_API)
                .then((response)=>{
//                    $('#addNew').modal('hide');
                  this.is_show_dialog = false;

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.getRows();
                }).catch((data)=> {
                    this.$Progress.finish();
                    Swal.fire("Failed!", data.message, "warning");
                });
            },

            onAddTimeset(index) {
                console.log('add', index);

                let timeset = this.makeDefaultTimeSet(1);
                if (this.form.filter_condition.filter_condition_time_sets[index] !== undefined) {
                    let source_timeset = this.form.filter_condition.filter_condition_time_sets[index];
                    timeset.week_index = source_timeset.week_index;
                    index++;
                }

                this.form.filter_condition.filter_condition_time_sets.splice(index,0, timeset);
                



            },
            onRemoveTimeset(index) {
                console.log('remove', index);
                this.form.filter_condition.filter_condition_time_sets.splice(index, 1);

            },

            makeDefaultTimeSet(week_index) {
                return {
                    week_index: week_index,
                    from: '09:00',
                    to: '17:00',
                };
            },
            makeDefaultTimeSets()
            {
                let timesets = [];
                for (let week_index = 1; week_index < 6; week_index++) {
                    let timeset = this.makeDefaultTimeSet(week_index);
                    timesets.push(timeset);
                }
                return timesets;
            },
            onChangeTimeset() {
                if (this.form.filter_condition.is_timeset) {
                    console.log('onChangeTimeset', this.form.filter_condition.filter_condition_time_sets);
                    //  表示するとき
                    if (this.form.filter_condition.filter_condition_time_sets == null || this.form.filter_condition.filter_condition_time_sets.length == 0) {
                        //  時間セットが一つも設定されていないので、初期値を設定

                        this.form.filter_condition.filter_condition_time_sets = this.makeDefaultTimeSets();
                        console.log('onChangeTimeset after', this.form.filter_condition.filter_condition_time_sets);
                    }

                }
            },

            onValidWitchChanged(row) {

                console.log('onValidWitchChanged', row.is_valid);

                this.updateRowStatus(row);



            },

            updateRowStatus(row){
                this.$Progress.start();
                console.log('row', row);
                axios.put(THIS_API + '/'+row.id, row)
                .then((response) => {
                    console.log('status', response);
                    if (!common.doResponse(response, false)) {
                        //  失敗した場合は再表示
                        this.getRows();
                    }
                    // success
                    this.$Progress.finish();
                }).catch((data)=> {
                    this.$Progress.finish();
                    Swal.fire("Failed!", data.message, "warning");
                    //  失敗した場合は再表示
                    this.getRows();
                });

            },


        },
        mounted() {
            console.log('Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.getRows();
            this.$Progress.finish();
        }
    }
</script>
