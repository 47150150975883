<style scoped>
.title_mark {
    position:  absolute;        /* 要素の配置方法を指定 */
    background-color: red;     /* 背景色指定 */
    height: 3px;
    top: 0px;                  /* 上からの位置指定 */
}
</style>
<template>
  <v-app id="inspire">
    <span v-if="!is_logged_in">
      <!-- ログインフォーム -->
      <login></login>


    </span>
    <span v-if="is_logged_in">


    <v-app-bar v-if="!is_show_text_only" app color="grey darken-3 white--text" clipped-right flat height="50">
      <template v-if="current_item && !current_item.sub_items">
        <v-toolbar-title class="text-subtitle-1 font-weight-black" v-if="current_item" color="white">{{current_item.text}}
          <v-sheet class="title_mark" :width="current_item.text.length + 'em'"></v-sheet>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </template>
      <template v-if="current_item && current_item.sub_items">
        <template v-for="(sub_item, index) in current_item.sub_items">
          <v-toolbar-title class="text-subtitle-1 font-weight-black" color="white" :key="index" v-if="can(sub_item.can)">
            <v-btn text class="text-subtitle-1 font-weight-black" color="white" @click="goLink(current_item, sub_item)">{{sub_item.text}}</v-btn>
            
            <v-sheet v-if="current_sub_item && current_sub_item.text == sub_item.text" class="title_mark" :width="sub_item.text.length + 2 + 'em'"></v-sheet>
          </v-toolbar-title>
        </template>
      </template>

    </v-app-bar>

    <v-navigation-drawer v-if="!is_show_text_only" v-model="drawer" permanent app color="grey darken-3" width="50">
      <v-img class="d-block text-center mx-auto mt-2" :src="image_path" max-width="75%"></v-img>
           <!--
        <v-avatar
          class="d-block text-center mx-auto mt-4"
          color="grey darken-1"
          size="36"
        ></v-avatar>

        <v-divider class="mx-3 my-5"></v-divider>
        -->
        <v-btn
            fab
            color="green"
            x-small
            class="d-block text-center mx-auto my-4"         
            >
            <v-avatar 
                color="grey lighten-1"
                size="26"
                >
                <v-icon size="30" color="white">mdi-account</v-icon>
            </v-avatar>
        </v-btn>


     <v-list width="50">
        <v-list-item-group
          v-model="menu_index"
          mandatory
          color="grey darken-5"
        >

            <v-tooltip
                v-for="(item, i) in items"
                :key="i"
                right>
                <template v-slot:activator="{ on, attrs }"  v-if="can(item.can)">

                    <v-list-item  :ripple="false"
                        @click="goLink(item)"
                    >
                        <v-list-item-icon 
                            color="white"
                            >
                            <v-icon
                                dense                
                                v-bind="attrs"
                                v-on="on"
                            color="blue-grey lighten-5" v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </template>
                <span>{{item.text}}</span>
            </v-tooltip>
  

            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

                    <v-list-item  :ripple="false"
                        @click="doLogout()"
                    >
                        <v-list-item-icon 
                            color="white"
                            >
                            <v-icon
                                dense                
                                v-bind="attrs"
                                v-on="on"
                            color="red ">mdi-power</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </template>
                <span>ログアウト</span>
            </v-tooltip>
        </v-list-item-group>
      </v-list>        


    </v-navigation-drawer>

    <!-- Main content キャッシュする -->
      <keep-alive>
        <router-view></router-view>
      </keep-alive>

      <vue-progress-bar></vue-progress-bar>


    </span>

  </v-app>
</template>

<script>
import { join } from 'path';
import Login from './Login.vue';
import settings from '../consts/settings'

  export default {
    components: { Login },
    props: {
        image_path: {
                type: String,
            },
        is_logged_in: {
          type: String,
        },
        

    },
    data: () => (
        { 
            drawer: null,
            current_item: null,
            items: [
                {
                    icon: 'mdi-view-list-outline',
                    link: '/contacts',
                    text: '通話一覧',
                    can: settings.Permissions.VIEW_CONTACT,
                },
                {
                    icon: 'mdi-account-multiple-outline',
                    link: '/users',
                    text: 'ユーザー',
                    can: settings.Permissions.VIEW_USER,
                    sub_items: [
                      {
                        link: '/users',
                        text: 'ユーザー',
                        can: settings.Permissions.VIEW_USER,
                      },
                      {
                        link: '/groups',
                        text: 'グループ',
                        can: settings.Permissions.VIEW_GROUP,
                      },
                      {
                        link: '/roles',
                        text: '役割',
                        can: settings.Permissions.VIEW_ROLE,
                      },

                    ],
                },
                {
                    icon: 'mdi-card-text-outline',
                    link: '/settings/filter_setting',
                    text: 'テキスト化対象の条件',
                    can: settings.Permissions.VIEW_FILTER_SETTING,
                },
                {
                    icon: 'mdi-export',
                    link: '/settings/recording_job_info',
                    text: '一括削除とエクスポート',
                    can: settings.Permissions.VIEW_RECORDING_JOB_INFO,
                },
                {
                    icon: 'mdi-cog',
                    link: '/settings/system_setting',
                    text: 'システム設定',
                    can: settings.Permissions.VIEW_SYSTEM_SETTING,
                },
                {
                    icon: 'mdi-phone-settings',
                    link: '/gc_settings/queues',
                    text: 'GenesysCloud設定',
                    can: settings.Permissions.VIEW_GC_SETTING,
                    sub_items: [
                      {
                        link: '/gc_settings/queues',
                        text: 'キュー',
                        can: settings.Permissions.VIEW_USER,
                      },
                      {
                        link: '/gc_settings/users',
                        text: 'エージェント',
                        can: settings.Permissions.VIEW_GROUP,
                      },
                      {
                        link: '/gc_settings/wrapupcodes',
                        text: 'ラップアップコード',
                        can: settings.Permissions.VIEW_ROLE,
                      },
                      {
                        link: '/gc_settings/divisions',
                        text: 'アクセス区分',
                        can: settings.Permissions.VIEW_ROLE,
                      },

                    ],
                },
            ],
            menu_index: 0,
        }
    ),
    computed: {
      //  /contacts/[conversation_id]/2  というパスのときはメニュー表示しないで通話テキストのみ表示する。genesys cloudないでiframe表示を想定
      is_show_text_only() {
          return (this.$route.name == 'contact_detail_with_id_type' && this.$route.params.id_type == 2) ? true : false;
      },
    },

    methods: {
        goLink(item, sub_item = null) {
          let link = item.link;
          if (sub_item) {
            link = sub_item.link;
          }
          if (this.$route.path != link) {
            this.$router.push(link);
            this.setCurrentItem(item, sub_item);
          }
        },
        //  ログアウト
        doLogout() {
          //  master.blade.phpのなかでform_logoutが記述されている
          document.form_logout.submit();
          return false;

        },
        setCurrentItem(item, sub_item) {
          this.current_item = item;
          if (sub_item == null && item.sub_items) {
            //  sub_itemの指定がないときは、先頭をsub_itemとして設定
            sub_item = item.sub_items[0];
          }
          this.current_sub_item = sub_item;
        }
    },
    created() {
      this.menu_index = this.items.findIndex(elm => elm.link == this.$route.path);
      if (this.menu_index !== null && this.menu_index >= 0) {
        this.setCurrentItem(this.items[this.menu_index]);
      } else {
        //  トップメニューに存在しないリンク
        this.items.forEach( (item) => {
          if (item.sub_items) {
            //  サブメニューと一致していないかチェック
            let sub_menu_index = item.sub_items.findIndex(elm => elm.link == this.$route.path);
            if (sub_menu_index !== null && sub_menu_index >= 0) {
              //  サブメニューのリンクで呼び出された
              this.setCurrentItem(item, item.sub_items[sub_menu_index]);
            }
          }

        });
      }
    },

  }
</script>