var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-card',{staticClass:"ma-3",attrs:{"flat":"","outlined":"","tile":"","loading":_vm.is_loading}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"1"}},[_c('v-toolbar-title',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_show_find),expression:"!is_show_find"}],staticClass:"text-subtitle-2"},[_vm._v(_vm._s(_vm.name)+"一覧")]),_vm._v(" "),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_show_find),expression:"is_show_find"}],staticClass:"mt-4",attrs:{"prepend-icon":"mdi-magnify","append-icon":"mdi-close"},on:{"blur":function($event){return _vm.getRows()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getRows()},"click:append":function($event){_vm.is_show_find=false;_vm.getRows();}},model:{value:(_vm.find_text),callback:function ($$v) {_vm.find_text=$$v},expression:"find_text"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.is_show_find=true;_vm.getRows();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("検索")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"grey darken-4"},on:{"click":function($event){return _vm.getRows()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-reload")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("表示更新")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.can(_vm.settings.Permissions.EDIT_GC_SETTING))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.doSync()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-database-sync")])],1):_vm._e()]}}])},[_vm._v(" "),_c('span',[_vm._v("同期")])])],1),_vm._v(" "),_c('v-simple-table',{staticClass:"v-table",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("名前")]),_vm._v(" "),_c('th',[_vm._v("登録日")]),_vm._v(" "),_c('th',[_vm._v("IDコピー")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.rows.data),function(row){return _c('tr',{key:row.id},[_c('td',{staticClass:"text-capitalize"},[_vm._v(_vm._s(row.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("moment_datetime")(row.created_at)))]),_vm._v(" "),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyId(row.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)],1)])}),0)]},proxy:true}])}),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-pagination',{staticClass:"my-4",attrs:{"circle":"","length":_vm.pager_length},on:{"next":function($event){return _vm.onPagination()},"previous":function($event){return _vm.onPagination()},"input":function($event){return _vm.onPagination()}},model:{value:(_vm.selected_page),callback:function ($$v) {_vm.selected_page=$$v},expression:"selected_page"}}),_vm._v(" "),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }