const Permissions = {
    VIEW_CONTACT : "通話表示",  //　通話詳細・一覧表示
    VIEW_USER : "ユーザー表示",  //　ユーザー詳細・一覧表示
    EDIT_USER : "ユーザー編集",  //　ユーザー編集・作成
    VIEW_GROUP : "グループ表示",  //　グループ詳細・一覧表示
    EDIT_GROUP : "グループ編集",  //　グループ編集・作成
    VIEW_ROLE : "役割表示",  //　役割詳細・一覧表示
    EDIT_ROLE : "役割編集",  //　役割編集・作成
    VIEW_FILTER_SETTING : "テキスト化対象の条件表示",
    EDIT_FILTER_SETTING : "テキスト化対象の条件編集",
    VIEW_RECORDING_JOB_INFO : "一括削除とエクスポート表示",
    EDIT_RECORDING_JOB_INFO : "一括削除とエクスポート編集",
    VIEW_SYSTEM_SETTING : "システム設定表示",
    EDIT_SYSTEM_SETTING : "システム設定編集",
    VIEW_GC_SETTING : "GenesysCloud設定表示",
    EDIT_GC_SETTING : "GenesysCloud設定編集",
}

export default {
    Permissions,
}