<template>
    <gc-setting :api="'/api/gc/queue'" :name="'キュー'"></gc-setting>
  </template>
    
  
  <script>
    import GcSetting from './GcSetting.vue';
    export default {
      components: { GcSetting },
    }
  
  </script>
    