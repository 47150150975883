<template>
    <v-main>
          <v-card  flat outlined tile class="ma-3" :loading="is_loading">
              <v-toolbar dense  elevation="1">
                  <v-toolbar-title v-show="!is_show_find"  class="text-subtitle-2">{{name}}一覧</v-toolbar-title>
  
                  <v-text-field @blur="getRows()"  @keyup.enter="getRows()" v-model="find_text" class="mt-4" v-show="is_show_find"
                    prepend-icon="mdi-magnify"
                    append-icon="mdi-close"
                    @click:append="is_show_find=false;getRows();"
                  >
                  </v-text-field>                
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon  @click="is_show_find=true;getRows();">
                          <v-icon color="primary">mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                      <span>検索</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon small color="grey darken-4" @click="getRows()">
                          <v-icon small>mdi-reload</v-icon>
                        </v-btn>
                      </template>
                      <span>表示更新</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" v-if="can(settings.Permissions.EDIT_GC_SETTING)" icon  @click="doSync()">
                          <v-icon color="primary">mdi-database-sync</v-icon>
                        </v-btn>
                      </template>
                      <span>同期</span>
                  </v-tooltip>


              </v-toolbar>
              <v-simple-table class="v-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>名前</th>
                      <th>登録日</th>
                      <th>IDコピー</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-for="row in rows.data" :key="row.id">
  
                      <td class="text-capitalize">{{row.name}}</td>
                      <td>{{row.created_at | moment_datetime}}</td>
                      <td>
                        <v-btn icon @click="copyId(row.id)" >
                            <v-icon small>mdi-content-copy</v-icon>
                        </v-btn>
                      </td>
  
                    </tr>
                  </tbody>
  
                </template>
              </v-simple-table>
  
  
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-pagination
                      v-model="selected_page"
                      circle
                      class="my-4"
                      :length="pager_length"
                      @next="onPagination()"
                      @previous="onPagination()"
                      @input="onPagination()"
                  ></v-pagination>
                  <v-spacer></v-spacer>
  
              </v-card-actions>
          </v-card>
  
  
  
  
    </v-main>
  </template>
  
  <script>
  import settings from '../../consts/settings';
  import common from '../../common';
  import moment from 'moment';
import axios from 'axios';
      export default {
        name: 'gc-setting',
        props: {
            name: {
                type: String
            },
            api: {
                type: String
            },
        },
          filters: {
              moment_datetime: function (date) {
                  return moment(date).format('YYYY-MM-DD HH:mm:ss');
              }
          },        
          computed: {
            settings: function () {
              return settings;
            },
            pager_length: function() {
              let length = 0;
              if (this.rows && this.rows.data) {
                length = this.rows.current_page;
                let count = this.rows.data.length;
                let limit = this.rows.per_page;
                if (count == limit) {
                  //  すべて取得できたとき。次のページもあるかもしれないので、ページ数をアップ
                  length = length + 1;
                }
              }
              return length;
            },
          },
  
          data () {
              return {
                is_loading: false,
                selected_page: 1,       //  現在選択されているページ（pager用）
                rows : {},
                is_show_find: false,
                find_text: null,
              }
          },
          methods: {
            //  ページネーションクリック
            onPagination() {
              //  チェックははずさないように
              this.getRows(this.selected_page);
            },
            getRows(page = 1) {
                this.is_loading = true;
  
                let find_text = this.is_show_find ? this.find_text : null;
                let param = find_text ? ('&find_text=' + encodeURIComponent(find_text)) : '';
                axios.get(this.api + '?page=' + page + param).then(({ data }) => {
                  this.rows = data.data;
                  this.is_loading = false;
                }).catch((data)=> {
                  Swal.fire("Failed!", data.message, "warning");
                  this.is_loading = false;
                });
            },
            copyId(id) {
                this.$copyText(id).then(function (e) {
                Toast.fire({
                  icon: 'success',
                  title: 'IDをクリップボードにコピーしました',
                });
              }, function (e) {
                  alert('Can not copy')
                  console.log(e)
              });
            },
            //  同期処理
            doSync() {
                common.confirmYesNo('確認', 'GenesysCloud上のデータと同期します', () => {
                        axios.post(this.api + '/sync').then(data => {
                        common.doResponse(data);
                    }).catch((data)=> {
                    Swal.fire("Failed!", data.message, "warning");
                    });
                }, null, '同期開始')

            },
  
          },
          mounted() {
          },
          created() {
  
              this.getRows();
          }
      }
  </script>
  